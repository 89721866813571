import { traverseResultA } from "../Logos.Client/fable_modules/FsToolkit.ErrorHandling.4.16.0/List.fs.js";
import { filter, map as map_2, choose, tryHead, empty, reverse, cons, singleton as singleton_1, ofArray, concat } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { FSharpResult$2 } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { singleton } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { parallel, sequential } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Async.js";
import { unwrap, some, value as value_4, defaultArg } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { format, replace as replace_1, split as split_1, isNullOrEmpty } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";
import { Union, Record, FSharpRef, toString } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { parse } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Guid.js";
import { addDays as addDays_1, compare, date as date_1, hour, addHours, specifyKind, addMinutes, minute as minute_1, year, day, month, today as today_1, parse as parse_1, toUniversalTime, toString as toString_1 } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Date.js";
import { tryParse, minValue, dayOfWeek as dayOfWeek_1, addDays } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { map as map_1, item, fold } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { create, replace, split } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/RegExp.js";
import { array_type, uint8_type, union_type, record_type, option_type, string_type } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { totalMinutes as totalMinutes_1, fromMinutes, fromHours, minutes as minutes_2, hours as hours_2, days } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { parse as parse_2 } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Int32.js";
import { equals } from "../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";

export function Result_traverse(x) {
    const input_1 = traverseResultA((x_1) => x_1, x);
    if (input_1.tag === 1) {
        return new FSharpResult$2(1, [concat(input_1.fields[0])]);
    }
    else {
        return new FSharpResult$2(0, [input_1.fields[0]]);
    }
}

export function AsyncResult_bindOfResult(y, x) {
    return singleton.Bind(x, (input_1_1) => {
        const input_2 = input_1_1;
        if (input_2.tag === 1) {
            return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
        }
        else {
            const x_1 = y(input_2.fields[0]);
            return singleton.Return(x_1);
        }
    });
}

export function AsyncResult_runSequential(x) {
    const input_2 = sequential(x);
    return singleton.Bind(input_2, (x$0027) => {
        let value;
        const input_1 = traverseResultA((x_1) => x_1, ofArray(x$0027));
        value = ((input_1.tag === 1) ? (new FSharpResult$2(1, [concat(input_1.fields[0])])) : (new FSharpResult$2(0, [input_1.fields[0]])));
        return singleton.Return(value);
    });
}

export function AsyncResult_runParallel(x) {
    const input_2 = parallel(x);
    return singleton.Bind(input_2, (x$0027) => {
        let value;
        const input_1 = traverseResultA((x_1) => x_1, ofArray(x$0027));
        value = ((input_1.tag === 1) ? (new FSharpResult$2(1, [concat(input_1.fields[0])])) : (new FSharpResult$2(0, [input_1.fields[0]])));
        return singleton.Return(value);
    });
}

export function Option_mapWithDefaultValue(map, defaultValue, option) {
    let input_1;
    return defaultArg((input_1 = option, (input_1 == null) ? undefined : some(map(value_4(input_1)))), defaultValue);
}

export function Option_ofString(value) {
    if (isNullOrEmpty(value)) {
        return undefined;
    }
    else {
        return value;
    }
}

export function Option_toString(value) {
    return defaultArg(value, "");
}

export function Option_ofBool(value) {
    if (value) {
        return value;
    }
    else {
        return undefined;
    }
}

export function Option_toBool(value) {
    return defaultArg(value, false);
}

export function Option_mapResult(mapper, value) {
    if (value == null) {
        return new FSharpResult$2(0, [undefined]);
    }
    else {
        const input = mapper(value_4(value));
        if (input.tag === 1) {
            return new FSharpResult$2(1, [input.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [some(input.fields[0])]);
        }
    }
}

export function Guid_parse(guidString) {
    try {
        return new FSharpResult$2(0, [parse(guidString)]);
    }
    catch (e_1) {
        return new FSharpResult$2(1, [singleton_1(toString(e_1))]);
    }
}

export function DateTime_toUTCString(dateTime) {
    return toString_1(toUniversalTime(dateTime), "yyyy\'-\'MM\'-\'dd\'T\'HH\':\'mm\':\'ss\'.\'ffffff\'Z\'");
}

export function DateTime_parseUTC(dateTime) {
    let input_1;
    try {
        return new FSharpResult$2(0, [(input_1 = Option_ofString(dateTime), (input_1 == null) ? undefined : parse_1(input_1))]);
    }
    catch (e_1) {
        return new FSharpResult$2(1, [singleton_1(toString(e_1))]);
    }
}

export function DateTime_calculateAge(dob) {
    const today = today_1();
    const delta = (((month(today) < month(dob)) ? true : ((month(today) === month(dob)) && (day(today) < day(dob)))) ? 1 : 0) | 0;
    return ((year(today) - year(dob)) - delta) | 0;
}

export function DateTime_roundToNearestInterval(roundDown, intervalMinutes, dt) {
    const minute = minute_1(dt) | 0;
    const roundedMinute = (roundDown ? (~~(minute / intervalMinutes) * intervalMinutes) : (~~(((minute + intervalMinutes) - 1) / intervalMinutes) * intervalMinutes)) | 0;
    return addMinutes(addMinutes(dt, -minute), roundedMinute);
}

export function DateTime_roundDownToNearestInterval(dt, interval) {
    return DateTime_roundToNearestInterval(true, interval, dt);
}

export function DateTime_roundUpToNearestInterval(dt, interval) {
    return DateTime_roundToNearestInterval(false, interval, dt);
}

export function DateTimeOffset_nextDayOfWeek(dayOfWeek, date) {
    return addDays(date, (7 + dayOfWeek) - dayOfWeek_1(date));
}

export function DateTimeOffset_parse(dateTime) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse(dateTime, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return new FSharpResult$2(0, [matchValue[1]]);
    }
    else {
        return new FSharpResult$2(1, [singleton_1("Invalid date time format.")]);
    }
}

export function System_DateTime__DateTime_ToUtcKind(this$) {
    return specifyKind(this$, 1);
}

export function System_DateTime__DateTime_ToLocalKind(this$) {
    return specifyKind(this$, 2);
}

export function System_DateTime__DateTime_ToDateWithHoursAndMinutes(this$) {
    return addMinutes(addHours(date_1(this$), hour(this$)), minute_1(this$));
}

export function System_DateTime__DateTime_GetDatesBetween_Static(start, finish) {
    const generateDates = (startDate_mut, endDate_mut, acc_mut) => {
        generateDates:
        while (true) {
            const startDate = startDate_mut, endDate = endDate_mut, acc = acc_mut;
            if (compare(startDate, endDate) <= 0) {
                startDate_mut = addDays_1(startDate, 1);
                endDate_mut = endDate;
                acc_mut = cons(startDate, acc);
                continue generateDates;
            }
            else {
                return reverse(acc);
            }
            break;
        }
    };
    return generateDates(start, finish, empty());
}

export function String_splitWord(word) {
    return fold((a, x) => {
        if (isNullOrEmpty(a)) {
            return x;
        }
        else {
            return `${a} ${x}`;
        }
    }, "", split(word, "([A-Z]?[a-z]+)"));
}

export function String_isNotNullOrEmpty(str) {
    return !isNullOrEmpty(str);
}

export function String_addSpaceBeforeWord(text) {
    return replace(text, "(\\B[A-Z])", " $1");
}

export function String_toTitleCase(text) {
    return replace(create("\\b\\w"), text, (delegateArg) => delegateArg[0].toLocaleUpperCase());
}

export class Customer_ExtractedCustomerInfo extends Record {
    constructor(Title, FirstName, MiddleName, LastName) {
        super();
        this.Title = Title;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
    }
}

export function Customer_ExtractedCustomerInfo_$reflection() {
    return record_type("Util.Customer.ExtractedCustomerInfo", [], Customer_ExtractedCustomerInfo, () => [["Title", option_type(string_type)], ["FirstName", string_type], ["MiddleName", option_type(string_type)], ["LastName", string_type]]);
}

export function Customer_split(splitToken, name) {
    const nameUpper = name.toLocaleUpperCase();
    const splitTokenUpper = splitToken.toLocaleUpperCase();
    if (nameUpper.indexOf(splitTokenUpper) >= 0) {
        return split_1(replace_1(name, splitToken, splitTokenUpper), [splitTokenUpper], undefined, 1);
    }
    else {
        return [];
    }
}

export function Customer_extractTitle(value) {
    let input_1, title, splitValues, x_2;
    return defaultArg((input_1 = tryHead(choose((x) => {
        if (value.indexOf(x + " ") >= 0) {
            return x;
        }
        else {
            return undefined;
        }
    }, ofArray(["Mr", "Mr.", "Mrs", "Mrs.", "Dr", "Dr.", "Miss", "Master"]))), (input_1 == null) ? undefined : ((title = input_1, (splitValues = Customer_split(title, value), (splitValues.length === 0) ? [undefined, value] : [title, (x_2 = fold((x_1, y) => (x_1 + y), "", splitValues.slice(0, splitValues.length)), x_2.trim())])))), [undefined, value]);
}

export function Customer_extractNames(value) {
    const splitValues = Customer_split(" ", value);
    const matchValue = splitValues.length | 0;
    switch (matchValue) {
        case 1:
            return {
                FirstName: item(0, splitValues),
                LastName: "",
            };
        case 2:
            return {
                FirstName: item(0, splitValues),
                LastName: item(1, splitValues),
            };
        case 3: {
            const FirstName = item(0, splitValues);
            const MiddleName = item(1, splitValues);
            return {
                FirstName: FirstName,
                LastName: item(2, splitValues),
                MiddleName: unwrap(MiddleName),
            };
        }
        default:
            return {
                FirstName: "",
                LastName: "",
            };
    }
}

export function Customer_splitCustomerNames(value) {
    const splitResult = Customer_split(" and ", value);
    if (splitResult.length === 0) {
        return [value];
    }
    else {
        return splitResult;
    }
}

export function Customer_getCustomerNames(value) {
    return ofArray(map_1((x) => {
        const patternInput = Customer_extractTitle(x);
        const names_1 = Customer_extractNames(patternInput[1]);
        return new Customer_ExtractedCustomerInfo(patternInput[0], names_1.FirstName, names_1.MiddleName, names_1.LastName);
    }, Customer_splitCustomerNames(value)));
}

export function System_TimeSpan__TimeSpan_ToDisplayDuration(this$) {
    if (days(this$) === 0) {
        return `${hours_2(this$)}h ${minutes_2(this$)}m`;
    }
    else {
        return `${days(this$)}d ${hours_2(this$)}h ${minutes_2(this$)}m`;
    }
}

export function System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(this$) {
    if (days(this$) === 0) {
        return `${hours_2(this$)}h ${minutes_2(this$)}m`;
    }
    else {
        return `${(days(this$) * 24) + hours_2(this$)}h ${minutes_2(this$)}m`;
    }
}

export function System_TimeSpan__TimeSpan_OfDisplayDurationFromHoursAndMinutes_Static_Z721C83C5(value) {
    const parts = split_1(value, [" "], undefined, 0);
    if (parts.length === 2) {
        const hours = replace_1(item(0, parts), "h", "").trim();
        const minutes = replace_1(item(1, parts), "m", "").trim();
        const hours_1 = parse_2(hours, 511, false, 32) | 0;
        const minutes_1 = parse_2(minutes, 511, false, 32) | 0;
        const input = (minutes_1 <= 59) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Minutes must be less than 60.")]));
        if (input.tag === 1) {
            return new FSharpResult$2(1, [input.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [fromHours(hours_1) + fromMinutes(minutes_1)]);
        }
    }
    else {
        return new FSharpResult$2(1, [singleton_1("Invalid duration format, expected format is \'Hh Mm\'.")]);
    }
}

export function System_TimeSpan__TimeSpan_OfDisplayDurationFromColonSeparator_Static_Z721C83C5(value) {
    const parts = split_1(value, [":"], undefined, 0);
    if (parts.length === 2) {
        const hours = item(0, parts).trim();
        const minutes = item(1, parts).trim();
        const hours_1 = parse_2(hours, 511, false, 32) | 0;
        const minutes_1 = parse_2(minutes, 511, false, 32) | 0;
        const input = (minutes_1 <= 59) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Minutes must be less than 60.")]));
        if (input.tag === 1) {
            return new FSharpResult$2(1, [input.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [fromHours(hours_1) + fromMinutes(minutes_1)]);
        }
    }
    else {
        return new FSharpResult$2(1, [singleton_1("Invalid duration format, expected format is \'H:M\'.")]);
    }
}

export function System_TimeSpan__TimeSpan_OfDisplayDurationFromDotSeparator_Static_Z721C83C5(value) {
    const parts = split_1(value, ["."], undefined, 0);
    if (parts.length === 2) {
        const hours = item(0, parts).trim();
        const minutePercentage = parse_2(item(1, parts).trim(), 511, false, 32) | 0;
        const hours_1 = parse_2(hours, 511, false, 32) | 0;
        const minutes = ~~((minutePercentage * 60) / 100) | 0;
        const input = (minutes <= 59) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Minutes must be less than 60.")]));
        if (input.tag === 1) {
            return new FSharpResult$2(1, [input.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [fromHours(hours_1) + fromMinutes(minutes)]);
        }
    }
    else {
        return new FSharpResult$2(1, [singleton_1("Invalid duration format, expected format is \'H.M\'.")]);
    }
}

export function TimeSpan_roundToNearestInterval(roundDown, intervalMinutes, ts) {
    let remainder;
    const totalMinutes = ~~totalMinutes_1(ts) | 0;
    return fromMinutes((remainder = ((totalMinutes % intervalMinutes) | 0), roundDown ? (totalMinutes - remainder) : (totalMinutes + ((remainder === 0) ? 0 : (intervalMinutes - remainder)))));
}

export function TimeSpan_roundDownToNearestInterval(ts, interval) {
    return TimeSpan_roundToNearestInterval(true, interval, ts);
}

export function TimeSpan_roundUpToNearestInterval(ts, interval) {
    return TimeSpan_roundToNearestInterval(false, interval, ts);
}

export function TimeSpan_parseDuration(value) {
    const result1 = System_TimeSpan__TimeSpan_OfDisplayDurationFromHoursAndMinutes_Static_Z721C83C5(value);
    const result2 = System_TimeSpan__TimeSpan_OfDisplayDurationFromColonSeparator_Static_Z721C83C5(value);
    const result3 = System_TimeSpan__TimeSpan_OfDisplayDurationFromDotSeparator_Static_Z721C83C5(value);
    const copyOfStruct = result1;
    if (copyOfStruct.tag === 0) {
        return new FSharpResult$2(0, [copyOfStruct.fields[0]]);
    }
    else {
        const copyOfStruct_1 = result2;
        if (copyOfStruct_1.tag === 0) {
            return new FSharpResult$2(0, [copyOfStruct_1.fields[0]]);
        }
        else {
            const copyOfStruct_2 = result3;
            if (copyOfStruct_2.tag === 0) {
                return new FSharpResult$2(0, [copyOfStruct_2.fields[0]]);
            }
            else {
                return new FSharpResult$2(1, [singleton_1("Invalid duration format.")]);
            }
        }
    }
}

export class DataFormat extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Raw", "CSV", "PDF"];
    }
}

export function DataFormat_$reflection() {
    return union_type("Util.DataFormat", [], DataFormat, () => [[], [], []]);
}

export class DataFormatPayload$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Raw", "CSV", "PDF"];
    }
}

export function DataFormatPayload$1_$reflection(gen0) {
    return union_type("Util.DataFormatPayload`1", [gen0], DataFormatPayload$1, () => [[["Item", gen0]], [["Item", string_type]], [["Item", array_type(uint8_type)]]]);
}

export function System_Decimal__Decimal_ToDisplayCurrency(this$) {
    return format('{0:' + "$#,##0.00" + '}', this$);
}

export function List_replace(itemToReplace, newItem, list) {
    return map_2((x) => {
        if (equals(x, itemToReplace)) {
            return newItem;
        }
        else {
            return x;
        }
    }, list);
}

export function List_remove(itemToRemove, list) {
    return filter((x) => !equals(x, itemToRemove), list);
}

