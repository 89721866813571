import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, class_type, record_type, list_type, string_type, bool_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class SignInState extends Record {
    constructor(IsBusy, Error$) {
        super();
        this.IsBusy = IsBusy;
        this.Error = Error$;
    }
}

export function SignInState_$reflection() {
    return record_type("Presentation.Pages.Index.SignInState", [], SignInState, () => [["IsBusy", bool_type], ["Error", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Index.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]]]);
}

export function init() {
    return [new SignInState(false, empty()), Cmd_none()];
}

export function update(msg, model) {
    return [new SignInState(model.IsBusy, singleton(toString(msg.fields[0]))), Cmd_none()];
}

export function IndexView() {
    let elems_4, elems_2, elems_1, elems, elems_3;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    return createElement("div", createObj(singleton((elems_4 = [createElement("div", createObj(Helpers_combineClasses("hero", ofArray([["className", join(" ", ["min-h-screen"])], (elems_2 = [createElement("div", {
        className: "hero-overlay bg-opacity-60",
    }), createElement("div", createObj(Helpers_combineClasses("hero-content", ofArray([["className", "text-center text-neutral-content"], (elems_1 = [createElement("div", createObj(ofArray([["className", "max-w-md"], (elems = [createElement("h1", {
        className: "mb-5 text-5xl font-bold",
        children: "10thDoor",
    }), createElement("p", {
        children: ["Welcome to 10thDoor."],
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("footer", createObj(Helpers_combineClasses("footer", ofArray([["className", "p-10 bg-neutral text-neutral-content"], (elems_3 = [createElement("p", {
        children: "Copyright © 10thDoor. All Rights Reserved.",
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))]))));
}

