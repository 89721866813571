import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, decimal_type, option_type, record_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { InvoiceRunItemDetailAggregate, InvoiceRunItemDetail, InvoiceRunItem, InvoiceRun, InvoiceGenerationMethod_$reflection, InvoiceRunStatus_$reflection } from "../Entity/Invoice.fs.js";

export class InvoiceRunDto extends Record {
    constructor(Id, RunNumber, OrgId, InvoiceRunStatus, TimesheetDateFrom, TimesheetDateTo, InvoiceGenerationMethod) {
        super();
        this.Id = Id;
        this.RunNumber = RunNumber;
        this.OrgId = OrgId;
        this.InvoiceRunStatus = InvoiceRunStatus;
        this.TimesheetDateFrom = TimesheetDateFrom;
        this.TimesheetDateTo = TimesheetDateTo;
        this.InvoiceGenerationMethod = InvoiceGenerationMethod;
    }
}

export function InvoiceRunDto_$reflection() {
    return record_type("Domain.Dto.Invoice.InvoiceRunDto", [], InvoiceRunDto, () => [["Id", class_type("System.Guid")], ["RunNumber", string_type], ["OrgId", class_type("System.Guid")], ["InvoiceRunStatus", InvoiceRunStatus_$reflection()], ["TimesheetDateFrom", class_type("System.DateTimeOffset")], ["TimesheetDateTo", class_type("System.DateTimeOffset")], ["InvoiceGenerationMethod", InvoiceGenerationMethod_$reflection()]]);
}

export function InvoiceRunDtoModule_ofInvoiceRun(invoiceRun) {
    return new InvoiceRunDto(invoiceRun.Id, invoiceRun.RunNumber, invoiceRun.OrgId, invoiceRun.InvoiceRunStatus, invoiceRun.TimesheetDateFrom, invoiceRun.TimesheetDateTo, invoiceRun.InvoiceGenerationMethod);
}

export function InvoiceRunDtoModule_toInvoiceRun(invoiceRunDto) {
    return new InvoiceRun(invoiceRunDto.Id, invoiceRunDto.RunNumber, invoiceRunDto.OrgId, invoiceRunDto.InvoiceRunStatus, invoiceRunDto.TimesheetDateFrom, invoiceRunDto.TimesheetDateTo, invoiceRunDto.InvoiceGenerationMethod);
}

export class InvoiceRunItemDto extends Record {
    constructor(Id, InvoiceRunId, CustomerId, XeroCustomerId, JobId, XeroInvoiceId, XeroInvoiceNumber, Amount, Created, Updated) {
        super();
        this.Id = Id;
        this.InvoiceRunId = InvoiceRunId;
        this.CustomerId = CustomerId;
        this.XeroCustomerId = XeroCustomerId;
        this.JobId = JobId;
        this.XeroInvoiceId = XeroInvoiceId;
        this.XeroInvoiceNumber = XeroInvoiceNumber;
        this.Amount = Amount;
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function InvoiceRunItemDto_$reflection() {
    return record_type("Domain.Dto.Invoice.InvoiceRunItemDto", [], InvoiceRunItemDto, () => [["Id", class_type("System.Guid")], ["InvoiceRunId", class_type("System.Guid")], ["CustomerId", class_type("System.Guid")], ["XeroCustomerId", option_type(class_type("System.Guid"))], ["JobId", class_type("System.Guid")], ["XeroInvoiceId", option_type(class_type("System.Guid"))], ["XeroInvoiceNumber", option_type(string_type)], ["Amount", decimal_type], ["Created", class_type("System.DateTimeOffset")], ["Updated", class_type("System.DateTimeOffset")]]);
}

export function InvoiceRunItemDtoModule_ofInvoiceRunItem(invoiceRunItem) {
    return new InvoiceRunItemDto(invoiceRunItem.Id, invoiceRunItem.InvoiceRunId, invoiceRunItem.CustomerId, invoiceRunItem.XeroCustomerId, invoiceRunItem.JobId, invoiceRunItem.XeroInvoiceId, invoiceRunItem.XeroInvoiceNumber, invoiceRunItem.Amount, invoiceRunItem.Created, invoiceRunItem.Updated);
}

export function InvoiceRunItemDtoModule_toInvoiceRunItem(invoiceRunItemDto) {
    return new InvoiceRunItem(invoiceRunItemDto.Id, invoiceRunItemDto.InvoiceRunId, invoiceRunItemDto.CustomerId, invoiceRunItemDto.XeroCustomerId, invoiceRunItemDto.JobId, invoiceRunItemDto.XeroInvoiceId, invoiceRunItemDto.XeroInvoiceNumber, invoiceRunItemDto.Amount, invoiceRunItemDto.Created, invoiceRunItemDto.Updated);
}

export class InvoiceRunItemDetailDto extends Record {
    constructor(Id, InvoiceRunItemId, TimesheetRunId, UserId, Rate, TotalWorkDuration, Amount) {
        super();
        this.Id = Id;
        this.InvoiceRunItemId = InvoiceRunItemId;
        this.TimesheetRunId = TimesheetRunId;
        this.UserId = UserId;
        this.Rate = Rate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.Amount = Amount;
    }
}

export function InvoiceRunItemDetailDto_$reflection() {
    return record_type("Domain.Dto.Invoice.InvoiceRunItemDetailDto", [], InvoiceRunItemDetailDto, () => [["Id", class_type("System.Guid")], ["InvoiceRunItemId", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Rate", decimal_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["Amount", decimal_type]]);
}

export function InvoiceRunItemDetailDtoModule_ofInvoiceRunItemDetail(invoiceRunItemDetail) {
    return new InvoiceRunItemDetailDto(invoiceRunItemDetail.Id, invoiceRunItemDetail.InvoiceRunItemId, invoiceRunItemDetail.TimesheetRunId, invoiceRunItemDetail.UserId, invoiceRunItemDetail.Rate, invoiceRunItemDetail.TotalWorkDuration, invoiceRunItemDetail.Amount);
}

export function InvoiceRunItemDetailDtoModule_toInvoiceRunItemDetail(invoiceRunItemDetailDto) {
    return new InvoiceRunItemDetail(invoiceRunItemDetailDto.Id, invoiceRunItemDetailDto.InvoiceRunItemId, invoiceRunItemDetailDto.TimesheetRunId, invoiceRunItemDetailDto.UserId, invoiceRunItemDetailDto.Rate, invoiceRunItemDetailDto.TotalWorkDuration, invoiceRunItemDetailDto.Amount);
}

export class InvoiceRunItemDetailAggregateDto extends Record {
    constructor(Ids, InvoiceRunItemId, TimesheetRunIds, UserId, Rate, TotalWorkDuration, Amount) {
        super();
        this.Ids = Ids;
        this.InvoiceRunItemId = InvoiceRunItemId;
        this.TimesheetRunIds = TimesheetRunIds;
        this.UserId = UserId;
        this.Rate = Rate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.Amount = Amount;
    }
}

export function InvoiceRunItemDetailAggregateDto_$reflection() {
    return record_type("Domain.Dto.Invoice.InvoiceRunItemDetailAggregateDto", [], InvoiceRunItemDetailAggregateDto, () => [["Ids", list_type(class_type("System.Guid"))], ["InvoiceRunItemId", class_type("System.Guid")], ["TimesheetRunIds", list_type(class_type("System.Guid"))], ["UserId", class_type("System.Guid")], ["Rate", decimal_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["Amount", decimal_type]]);
}

export function InvoiceRunItemDetailAggregateDtoModule_ofInvoiceRunItemDetailAggregate(invoiceRunItemDetailAggregate) {
    return new InvoiceRunItemDetailAggregateDto(invoiceRunItemDetailAggregate.Ids, invoiceRunItemDetailAggregate.InvoiceRunItemId, invoiceRunItemDetailAggregate.TimesheetRunIds, invoiceRunItemDetailAggregate.UserId, invoiceRunItemDetailAggregate.Rate, invoiceRunItemDetailAggregate.TotalWorkDuration, invoiceRunItemDetailAggregate.Amount);
}

export function InvoiceRunItemDetailAggregateDtoModule_toInvoiceRunItemDetailAggregate(invoiceRunItemDetailAggregateDto) {
    return new InvoiceRunItemDetailAggregate(invoiceRunItemDetailAggregateDto.Ids, invoiceRunItemDetailAggregateDto.InvoiceRunItemId, invoiceRunItemDetailAggregateDto.TimesheetRunIds, invoiceRunItemDetailAggregateDto.UserId, invoiceRunItemDetailAggregateDto.Rate, invoiceRunItemDetailAggregateDto.TotalWorkDuration, invoiceRunItemDetailAggregateDto.Amount);
}

