import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchInvoiceRuns } from "../../../AppDomain/UseCase/Invoice.fs.js";
import { IndexView as IndexView_3, Msg$1, update as update_1, init as init_1 } from "../BaseSearch.fs.js";
import { createObj, uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { Daisy_h5, alignIconCenter, alignCellTextTop, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./InvoiceRun.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton as singleton_1, append, map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { DateTime_dateTimeToString } from "../../../AppDomain/Util.fs.js";
import { fromDateTimeOffset } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { InvoiceGenerationMethodModule_toString } from "../../../../Logos.Shared/Domain/Entity/Invoice.fs.js";
import { IndexView as IndexView_2 } from "./InvoiceRunEdit.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchInvoiceRuns(appRoot.InvoiceService, selectedOrgId, searchText, pagination));
}

export function init() {
    return init_1(uncurry2(searchFunc()));
}

export function update(msg, state) {
    return update_1(uncurry2(searchFunc()), msg, state);
}

export function invoiceRunTableSearchView(props) {
    let elems, elems_6, children_3, children_1, children_5;
    const children_8 = ofArray([createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = [createElement(showModalEx, {
        dialogId: "id-invoice-run-search-add-invoice-run",
        header: "",
        buttonType: new ButtonType(0, ["Generate Invoices"]),
        width: new Daisy_DialogWidth(3, []),
        heightPercent: "70",
        body: () => createElement(IndexView_1, null),
        onShow: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$1(1, []));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_6 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
        width: 10 + "%",
        children: "Run #",
    }), createElement("th", {
        width: 12 + "%",
        children: "Created",
    }), createElement("th", {
        width: 10 + "%",
        children: "Approved",
    }), createElement("th", {
        width: 15 + "%",
        children: "Canceled",
    }), createElement("th", {
        width: 8 + "%",
        children: "Method",
    }), createElement("th", {
        width: 2 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_5 = toList(delay(() => map((invoiceRun) => {
        let elems_5, elems_3, elems_2, elems_1, elems_4;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_5 = [createElement("td", createObj(ofArray([alignCellTextTop, (elems_3 = [createElement("div", createObj(singleton((elems_2 = [createElement("span", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
        }], (elems_1 = [createElement("i", {
            className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => {
                const matchValue_1 = invoiceRun.InvoiceRunStatus;
                return (matchValue_1.tag === 3) ? singleton_1("fa-check") : ((matchValue_1.tag === 2) ? singleton_1("fa-times") : singleton_1("fa-hourglass"));
            }))))),
            style: createObj(toList(delay(() => append(singleton_1(["marginRight", "8px"]), delay(() => {
                const matchValue_2 = invoiceRun.InvoiceRunStatus;
                return (matchValue_2.tag === 3) ? singleton_1(["color", "green"]) : ((matchValue_2.tag === 2) ? singleton_1(["color", "red"]) : singleton_1(["color", "orange"]));
            }))))),
        }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", invoiceRun.RunNumber]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", createObj(toList(delay(() => append(singleton_1(alignCellTextTop), delay(() => {
            let matchValue_3;
            return singleton_1(["children", (matchValue_3 = invoiceRun.InvoiceRunStatus, (matchValue_3.tag === 1) ? DateTime_dateTimeToString(fromDateTimeOffset(matchValue_3.fields[0].DateCreated, 0)) : "")]);
        })))))), createElement("td", createObj(toList(delay(() => append(singleton_1(alignCellTextTop), delay(() => {
            let matchValue_4;
            return singleton_1(["children", (matchValue_4 = invoiceRun.InvoiceRunStatus, (matchValue_4.tag === 3) ? DateTime_dateTimeToString(fromDateTimeOffset(matchValue_4.fields[0].DateApproved, 0)) : "")]);
        })))))), createElement("td", createObj(toList(delay(() => append(singleton_1(alignCellTextTop), delay(() => {
            let matchValue_5;
            return singleton_1(["children", (matchValue_5 = invoiceRun.InvoiceRunStatus, (matchValue_5.tag === 2) ? DateTime_dateTimeToString(fromDateTimeOffset(matchValue_5.fields[0].DateCanceled, 0)) : "")]);
        })))))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", InvoiceGenerationMethodModule_toString(invoiceRun.InvoiceGenerationMethod)]]))), createElement("td", createObj(ofArray([alignIconCenter, (elems_4 = toList(delay(() => singleton_1(createElement(showModalEx, {
            dialogId: invoiceRun.Id,
            header: "",
            buttonType: new ButtonType(1, ["fa-edit"]),
            width: new Daisy_DialogWidth(5, []),
            heightPercent: "90",
            body: () => {
                const matchValue = props.state.EntityToEdit;
                if (matchValue == null) {
                    const xs = [Daisy_h5("No Invoice Run Selected.")];
                    return react.createElement(react.Fragment, {}, ...xs);
                }
                else {
                    return createElement(IndexView_2, {
                        invoiceRunId: matchValue,
                        onInvoiceUpdated: () => {
                            props.dispatch(new Msg$1(9, [true]));
                        },
                    });
                }
            },
            onShow: () => {
                props.dispatch(new Msg$1(4, [invoiceRun.Id]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(2, []));
            },
        })))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function IndexView() {
    return createElement(IndexView_3, {
        entityName: "Invoice Runs",
        entityTable: invoiceRunTableSearchView,
        searchFunc: uncurry2(searchFunc()),
    });
}

