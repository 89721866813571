import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, record_type, list_type, anonRecord_type, string_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { InvoiceGenerationMethod, InvoiceGenerationMethod_$reflection } from "../../../../Logos.Shared/Domain/Entity/Invoice.fs.js";
import { JobDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { ofArray, singleton as singleton_1, isEmpty, empty, filter, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { generateInvoiceRun } from "../../../AppDomain/UseCase/Invoice.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { getActiveJobs } from "../../../AppDomain/UseCase/Job.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { map as map_1, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_info, Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(IsInvoiceRunGenerated, GeneratedInvoiceRunNumber, InvoiceGenerationMethod, Jobs, IsBusy, Errors) {
        super();
        this.IsInvoiceRunGenerated = IsInvoiceRunGenerated;
        this.GeneratedInvoiceRunNumber = GeneratedInvoiceRunNumber;
        this.InvoiceGenerationMethod = InvoiceGenerationMethod;
        this.Jobs = Jobs;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.InvoiceRun.State", [], State, () => [["IsInvoiceRunGenerated", bool_type], ["GeneratedInvoiceRunNumber", string_type], ["InvoiceGenerationMethod", InvoiceGenerationMethod_$reflection()], ["Jobs", list_type(anonRecord_type(["IsChecked", bool_type], ["Job", JobDetail_$reflection()]))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GenerateInvoiceRun", "GenerateInvoiceRunResponse", "SetInvoiceGenerationMethod", "GetJobsResponse", "SelectJob", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.InvoiceRun.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", InvoiceGenerationMethod_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(JobDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(JobDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item1", bool_type], ["Item2", JobDetail_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function generateInvoiceRunCmd(state) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        const selectedJobs = (state.InvoiceGenerationMethod.tag === 1) ? map((x_1) => x_1.Job.Id, filter((x) => x.IsChecked, state.Jobs)) : empty();
        let asyncResult_1;
        const value_1_1 = !(equals(state.InvoiceGenerationMethod, new InvoiceGenerationMethod(1, [])) && isEmpty(selectedJobs)) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Please select at least one job.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return generateInvoiceRun(appRoot.InvoiceService, AppRootModule_getSelectedOrgId(), state.InvoiceGenerationMethod, selectedJobs);
            }
        });
    }));
}

export function getJobsCmd() {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => getActiveJobs(appRoot.JobService, AppRootModule_getSelectedOrgId())));
}

export function init() {
    return [new State(false, "", new InvoiceGenerationMethod(0, []), empty(), new Busy(0, []), empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsInvoiceRunGenerated, state.GeneratedInvoiceRunNumber, state.InvoiceGenerationMethod, state.Jobs, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(true, msg.fields[0].fields[0], state.InvoiceGenerationMethod, state.Jobs, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 2:
            if (equals(msg.fields[0], new InvoiceGenerationMethod(0, []))) {
                return [new State(state.IsInvoiceRunGenerated, state.GeneratedInvoiceRunNumber, msg.fields[0], empty(), state.IsBusy, state.Errors), Cmd_none()];
            }
            else {
                return [new State(state.IsInvoiceRunGenerated, state.GeneratedInvoiceRunNumber, msg.fields[0], state.Jobs, new Busy(1, []), state.Errors), getJobsCmd()];
            }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsInvoiceRunGenerated, state.GeneratedInvoiceRunNumber, state.InvoiceGenerationMethod, state.Jobs, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.IsInvoiceRunGenerated, state.GeneratedInvoiceRunNumber, state.InvoiceGenerationMethod, map((job) => ({
                    IsChecked: false,
                    Job: job,
                }), msg.fields[0].fields[0]), new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 4:
            return [new State(state.IsInvoiceRunGenerated, state.GeneratedInvoiceRunNumber, state.InvoiceGenerationMethod, map((x_2) => {
                if (equals(x_2.Job, msg.fields[1])) {
                    return {
                        IsChecked: msg.fields[0],
                        Job: x_2.Job,
                    };
                }
                else {
                    return x_2;
                }
            }, state.Jobs), state.IsBusy, state.Errors), Cmd_none()];
        case 5:
            return [new State(state.IsInvoiceRunGenerated, state.GeneratedInvoiceRunNumber, state.InvoiceGenerationMethod, state.Jobs, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [state, generateInvoiceRunCmd(state)];
    }
}

export function IndexView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container(toList(delay(() => {
        let children;
        return append(singleton_2((children = singleton_1(Daisy_h4("Generate Invoices")), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
            let children_2;
            return append(singleton_2((children_2 = singleton_1(createElement(Daisy_progressState, {
                busy: state_1.IsBusy,
            })), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => append(singleton_2(Daisy_info(singleton_1("Invoices will be generated for all active Jobs that fall within the invoice frequency period."))), delay(() => append(state_1.IsInvoiceRunGenerated ? singleton_2(Daisy_info(singleton_1(`Invoices generated successfully. New Timesheet Run number is ${state_1.GeneratedInvoiceRunNumber}.`))) : empty_1(), delay(() => {
                let elems_8, elems_2, elems_1, elems_7, elems_3, elems_6, elems_5;
                return append(singleton_2(viewBox("Invoice Generation Method", new TitleSize(1, []), singleton_1(createElement("div", createObj(ofArray([["className", "grid grid-cols-2 gap-4"], (elems_8 = [createElement("div", createObj(ofArray([["className", "flex flex-row vertical-center"], (elems_2 = [createElement("div", createObj(ofArray([["className", "mt-1/2 mr-2"], (elems_1 = [createElement("input", createObj(Helpers_combineClasses("radio", ofArray([["type", "radio"], ["className", "radio-xs"], ["checked", equals(state_1.InvoiceGenerationMethod, new InvoiceGenerationMethod(0, []))], ["onChange", (ev) => {
                    const _arg_2 = ev.target.checked;
                    dispatch(new Msg(2, [new InvoiceGenerationMethod(0, [])]));
                }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", {
                    children: "Invoice frequency period",
                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", "flex flex-row vertical-center"], (elems_7 = [createElement("div", createObj(ofArray([["className", "mt-1/2 mr-2"], (elems_3 = [createElement("input", createObj(Helpers_combineClasses("radio", ofArray([["type", "radio"], ["className", "radio-xs"], ["checked", equals(state_1.InvoiceGenerationMethod, new InvoiceGenerationMethod(1, []))], ["onChange", (ev_1) => {
                    const _arg_3 = ev_1.target.checked;
                    dispatch(new Msg(2, [new InvoiceGenerationMethod(1, [])]));
                }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(Helpers_combineClasses("form-control", ofArray([["className", "w-full"], (elems_6 = [createElement("div", {
                    children: "Manual job selection",
                }), createElement("div", createObj(ofArray([["className", "border border-gray-300 rounded-md p-2 h-40 overflow-y-auto"], (elems_5 = toList(delay(() => (equals(state_1.InvoiceGenerationMethod, new InvoiceGenerationMethod(1, [])) ? map_1((job) => {
                    let elems_4;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_4 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-xs"], ["className", join(" ", ["mr-2"])], ["checked", job.IsChecked], ["onChange", (ev_2) => {
                        let tupledArg;
                        dispatch((tupledArg = [ev_2.target.checked, job.Job], new Msg(4, [tupledArg[0], tupledArg[1]])));
                    }]])))), createElement("span", {
                        className: "label-text",
                        children: job.Job.Title,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
                }, state_1.Jobs) : empty_1()))), ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))))), delay(() => singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Generate Invoices"], ["disabled", state_1.IsInvoiceRunGenerated], ["onClick", (_arg_4) => {
                    dispatch(new Msg(0, []));
                }]])))))));
            }))))));
        }))));
    })));
}

