import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchTimesheets } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { IndexView as IndexView_3, Msg$1, update as update_1, init as init_1 } from "../BaseSearch.fs.js";
import { createObj, uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { Daisy_h5, alignIconCenter, alignCellTextTop, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./TimesheetRun.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { collect, singleton as singleton_1, append, map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { value as value_87 } from "../../../../Logos.Shared/Domain/Entity/DateRange.fs.js";
import { DateTime_dateTimeToString, DateTime_dateToString } from "../../../AppDomain/Util.fs.js";
import { map as map_1, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { AddressDtoModule_toAddressString } from "../../../../Logos.Shared/Domain/Dto/Address.fs.js";
import { IndexView as IndexView_2 } from "./TimesheetEdit.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchTimesheets(appRoot.ShiftService, selectedOrgId, searchText, pagination));
}

export function init() {
    return init_1(uncurry2(searchFunc()));
}

export function update(msg, state) {
    return update_1(uncurry2(searchFunc()), msg, state);
}

export function timesheetTable(props) {
    let elems, elems_7, children_3, children_1, children_9;
    const children_12 = ofArray([createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = [createElement(showModalEx, {
        dialogId: "id-timesheet-search-add-timesheet",
        header: "",
        buttonType: new ButtonType(0, ["Generate Timesheets"]),
        width: new Daisy_DialogWidth(3, []),
        heightPercent: "80",
        body: () => createElement(IndexView_1, null),
        onShow: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$1(1, []));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_7 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
        width: 10 + "%",
        children: "Run #",
    }), createElement("th", {
        width: 18 + "%",
        children: "Run date range",
    }), createElement("th", {
        width: 12 + "%",
        children: "Created",
    }), createElement("th", {
        width: 10 + "%",
        children: "Approved",
    }), createElement("th", {
        width: 15 + "%",
        children: "Canceled",
    }), createElement("th", {
        width: 25 + "%",
        children: "Jobs",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_9 = toList(delay(() => map((timesheet) => {
        let elems_6;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_6 = toList(delay(() => {
            let elems_3, elems_2, elems_1;
            return append(singleton_1(createElement("td", createObj(ofArray([alignCellTextTop, (elems_3 = [createElement("div", createObj(singleton((elems_2 = [createElement("span", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
            }], (elems_1 = [createElement("i", {
                className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => ((timesheet.Approved != null) ? singleton_1("fa-check") : ((timesheet.Canceled != null) ? singleton_1("fa-times") : singleton_1("fa-hourglass")))))))),
                style: createObj(toList(delay(() => append(singleton_1(["marginRight", "8px"]), delay(() => ((timesheet.Approved != null) ? singleton_1(["color", "green"]) : ((timesheet.Canceled != null) ? singleton_1(["color", "red"]) : singleton_1(["color", "orange"])))))))),
            }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", timesheet.RunNumber]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                const dateRange = value_87(timesheet.DateRange);
                return append(singleton_1(createElement("td", createObj(ofArray([alignCellTextTop, ["children", `${DateTime_dateToString(dateRange.Start)} - ${DateTime_dateToString(dateRange.Finish)}`]])))), delay(() => append(singleton_1(createElement("td", createObj(ofArray([alignCellTextTop, ["children", `${DateTime_dateTimeToString(timesheet.Created.DateCreated)}`]])))), delay(() => append(singleton_1(createElement("td", createObj(ofArray([alignCellTextTop, ["children", defaultArg(map_1((x_1) => DateTime_dateTimeToString(x_1.DateApproved), timesheet.Approved), "")]])))), delay(() => append(singleton_1(createElement("td", createObj(ofArray([alignCellTextTop, ["children", defaultArg(map_1((x_2) => DateTime_dateTimeToString(x_2.DateCanceled), timesheet.Canceled), "")]])))), delay(() => {
                    let children_5, elems_4;
                    return append(singleton_1((children_5 = singleton(createElement("div", createObj(ofArray([alignCellTextTop, ["key", timesheet.Id], (elems_4 = toList(delay(() => collect((job) => append(singleton_1(createElement("div", {
                        className: join(" ", ["font-bold"]),
                        children: job.Title,
                    })), delay(() => singleton_1(createElement("div", {
                        children: AddressDtoModule_toAddressString(job.Address),
                    })))), timesheet.Jobs))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), createElement("td", {
                        children: reactApi.Children.toArray(Array.from(children_5)),
                    }))), delay(() => {
                        let children_7, elems_5;
                        return singleton_1((children_7 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems_5 = [createElement(showModalEx, {
                            dialogId: timesheet.Id,
                            header: "",
                            buttonType: new ButtonType(1, ["fa-edit"]),
                            width: new Daisy_DialogWidth(5, []),
                            heightPercent: "90",
                            body: () => {
                                const matchValue = props.state.EntityToEdit;
                                if (matchValue == null) {
                                    const xs = [Daisy_h5("No Timesheet Run Selected.")];
                                    return react.createElement(react.Fragment, {}, ...xs);
                                }
                                else {
                                    return createElement(IndexView_2, {
                                        onTimesheetUpdated: () => {
                                            props.dispatch(new Msg$1(9, [true]));
                                        },
                                        timesheetRunId: matchValue,
                                    });
                                }
                            },
                            onShow: () => {
                                props.dispatch(new Msg$1(4, [timesheet.Id]));
                            },
                            onClose: () => {
                                props.dispatch(new Msg$1(2, []));
                            },
                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("td", {
                            children: reactApi.Children.toArray(Array.from(children_7)),
                        })));
                    }));
                }))))))));
            }));
        })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_9)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_12)),
    });
}

export function IndexView() {
    return createElement(IndexView_3, {
        entityName: "Timesheets",
        entityTable: timesheetTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

