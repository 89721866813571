import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, decimal_type, option_type, record_type, string_type, union_type, anonRecord_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class InvoiceRunStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoState", "Created", "Canceled", "Approved"];
    }
}

export function InvoiceRunStatus_$reflection() {
    return union_type("Domain.Entity.Invoice.InvoiceRunStatus", [], InvoiceRunStatus, () => [[], [["Item", anonRecord_type(["CreatedBy", class_type("System.Guid")], ["DateCreated", class_type("System.DateTimeOffset")])]], [["Item", anonRecord_type(["CanceledBy", class_type("System.Guid")], ["DateCanceled", class_type("System.DateTimeOffset")])]], [["Item", anonRecord_type(["ApprovedBy", class_type("System.Guid")], ["DateApproved", class_type("System.DateTimeOffset")])]]]);
}

export function InvoiceRunStatusModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Created";
        case 2:
            return "Canceled";
        case 3:
            return "Approved";
        default:
            return "No State";
    }
}

export function InvoiceRunStatusModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 10;
        case 2:
            return 20;
        case 3:
            return 30;
        default:
            return 0;
    }
}

export class InvoiceGenerationMethod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FrequencyPeriod", "ManualJobSelection"];
    }
}

export function InvoiceGenerationMethod_$reflection() {
    return union_type("Domain.Entity.Invoice.InvoiceGenerationMethod", [], InvoiceGenerationMethod, () => [[], []]);
}

export function InvoiceGenerationMethodModule_toInt(_arg) {
    if (_arg.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export function InvoiceGenerationMethodModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new InvoiceGenerationMethod(0, []);
        case 1:
            return new InvoiceGenerationMethod(1, []);
        default:
            return new InvoiceGenerationMethod(0, []);
    }
}

export function InvoiceGenerationMethodModule_toString(_arg) {
    if (_arg.tag === 1) {
        return "Manual";
    }
    else {
        return "Period Frequency";
    }
}

export class InvoiceRun extends Record {
    constructor(Id, RunNumber, OrgId, InvoiceRunStatus, TimesheetDateFrom, TimesheetDateTo, InvoiceGenerationMethod) {
        super();
        this.Id = Id;
        this.RunNumber = RunNumber;
        this.OrgId = OrgId;
        this.InvoiceRunStatus = InvoiceRunStatus;
        this.TimesheetDateFrom = TimesheetDateFrom;
        this.TimesheetDateTo = TimesheetDateTo;
        this.InvoiceGenerationMethod = InvoiceGenerationMethod;
    }
}

export function InvoiceRun_$reflection() {
    return record_type("Domain.Entity.Invoice.InvoiceRun", [], InvoiceRun, () => [["Id", class_type("System.Guid")], ["RunNumber", string_type], ["OrgId", class_type("System.Guid")], ["InvoiceRunStatus", InvoiceRunStatus_$reflection()], ["TimesheetDateFrom", class_type("System.DateTimeOffset")], ["TimesheetDateTo", class_type("System.DateTimeOffset")], ["InvoiceGenerationMethod", InvoiceGenerationMethod_$reflection()]]);
}

export class InvoiceRunItem extends Record {
    constructor(Id, InvoiceRunId, CustomerId, XeroCustomerId, JobId, XeroInvoiceId, XeroInvoiceNumber, Amount, Created, Updated) {
        super();
        this.Id = Id;
        this.InvoiceRunId = InvoiceRunId;
        this.CustomerId = CustomerId;
        this.XeroCustomerId = XeroCustomerId;
        this.JobId = JobId;
        this.XeroInvoiceId = XeroInvoiceId;
        this.XeroInvoiceNumber = XeroInvoiceNumber;
        this.Amount = Amount;
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function InvoiceRunItem_$reflection() {
    return record_type("Domain.Entity.Invoice.InvoiceRunItem", [], InvoiceRunItem, () => [["Id", class_type("System.Guid")], ["InvoiceRunId", class_type("System.Guid")], ["CustomerId", class_type("System.Guid")], ["XeroCustomerId", option_type(class_type("System.Guid"))], ["JobId", class_type("System.Guid")], ["XeroInvoiceId", option_type(class_type("System.Guid"))], ["XeroInvoiceNumber", option_type(string_type)], ["Amount", decimal_type], ["Created", class_type("System.DateTimeOffset")], ["Updated", class_type("System.DateTimeOffset")]]);
}

export class InvoiceRunItemDetail extends Record {
    constructor(Id, InvoiceRunItemId, TimesheetRunId, UserId, Rate, TotalWorkDuration, Amount) {
        super();
        this.Id = Id;
        this.InvoiceRunItemId = InvoiceRunItemId;
        this.TimesheetRunId = TimesheetRunId;
        this.UserId = UserId;
        this.Rate = Rate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.Amount = Amount;
    }
}

export function InvoiceRunItemDetail_$reflection() {
    return record_type("Domain.Entity.Invoice.InvoiceRunItemDetail", [], InvoiceRunItemDetail, () => [["Id", class_type("System.Guid")], ["InvoiceRunItemId", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Rate", decimal_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["Amount", decimal_type]]);
}

export class InvoiceRunItemDetailAggregate extends Record {
    constructor(Ids, InvoiceRunItemId, TimesheetRunIds, UserId, Rate, TotalWorkDuration, Amount) {
        super();
        this.Ids = Ids;
        this.InvoiceRunItemId = InvoiceRunItemId;
        this.TimesheetRunIds = TimesheetRunIds;
        this.UserId = UserId;
        this.Rate = Rate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.Amount = Amount;
    }
}

export function InvoiceRunItemDetailAggregate_$reflection() {
    return record_type("Domain.Entity.Invoice.InvoiceRunItemDetailAggregate", [], InvoiceRunItemDetailAggregate, () => [["Ids", list_type(class_type("System.Guid"))], ["InvoiceRunItemId", class_type("System.Guid")], ["TimesheetRunIds", list_type(class_type("System.Guid"))], ["UserId", class_type("System.Guid")], ["Rate", decimal_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["Amount", decimal_type]]);
}

