import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { anonRecord_type, decimal_type, option_type, list_type, bool_type, record_type, enum_type, int32_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { PaymentRunComprehensive, TimesheetRunDetail_$reflection, TimesheetReportByWorker, TimesheetRunUserSummaryReport, ShiftDetail, Holiday, PaymentComprehensive, Payment, PaymentRunTimesheetRun, PaymentRun, TimesheetShift, TimesheetComprehensive, TimesheetOfUser, Holiday_$reflection, Timesheet, TimesheetRun, TimesheetJob, ShiftLogTypeModule_ofInt, ShiftLogTypeModule_toInt, ShiftLog, ShiftLogType_$reflection, ShiftComprehensive, ShiftPostCheckItem_$reflection, ShiftPreCheckItem_$reflection, Shift, ShiftPostCheckItem, ShiftPreCheckItem } from "../Entity/Shift.fs.js";
import { LocationDtoModule_toLocationEx, LocationDtoModule_ofLocationEx, LocationDto_$reflection } from "./RememberWhere.fs.js";
import { map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { defaultArg, ofNullable, toNullable } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { fromDate, toUniversalTime } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { parse, toString } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/TimeOnly.js";
import { minValue } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Date.js";
import { Location } from "../Entity/Location.fs.js";
import { AddressDtoModule_toAddress, AddressDtoModule_ofAddress, AddressDto_$reflection } from "./Address.fs.js";
import { DateRange_$reflection } from "../Entity/DateRange.fs.js";
import { LeaveRequestDtoModule_toLeaveRequest, LeaveRequestDtoModule_ofLeaveRequest, LeaveRequestDto_$reflection } from "./User.fs.js";
import { PayrollSystemInfo_$reflection, EmploymentType_$reflection } from "../Entity/User.fs.js";
import { SimproContractorWorkOrder } from "../Entity/Simpro.fs.js";

export class ShiftPreCheckItemDto extends Record {
    constructor(Id, Name, Description, Priority, Status, Notes, Created) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Status = (Status | 0);
        this.Notes = Notes;
        this.Created = Created;
    }
}

export function ShiftPreCheckItemDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftPreCheckItemDto", [], ShiftPreCheckItemDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Status", enum_type("Domain.Entity.Shift.ShiftCheckStatus", int32_type, [["AwaitingAction", 0], ["Accepted", 10], ["Declined", 20]])], ["Notes", string_type], ["Created", class_type("System.DateTimeOffset")]]);
}

export function ShiftPreCheckItemDtoModule_ofShiftPreCheckItem(shiftPreCheckItem) {
    return new ShiftPreCheckItemDto(shiftPreCheckItem.Id, shiftPreCheckItem.Name, shiftPreCheckItem.Description, shiftPreCheckItem.Priority, shiftPreCheckItem.Status, shiftPreCheckItem.Notes, shiftPreCheckItem.Created);
}

export function ShiftPreCheckItemDtoModule_toShiftPreCheckItem(shiftPreCheckItem) {
    return new ShiftPreCheckItem(shiftPreCheckItem.Id, shiftPreCheckItem.Name, shiftPreCheckItem.Description, shiftPreCheckItem.Priority, shiftPreCheckItem.Status, shiftPreCheckItem.Notes, shiftPreCheckItem.Created);
}

export class ShiftPostCheckItemDto extends Record {
    constructor(Id, Name, Description, Priority, Status, Notes, Created) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Status = (Status | 0);
        this.Notes = Notes;
        this.Created = Created;
    }
}

export function ShiftPostCheckItemDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftPostCheckItemDto", [], ShiftPostCheckItemDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Status", enum_type("Domain.Entity.Shift.ShiftCheckStatus", int32_type, [["AwaitingAction", 0], ["Accepted", 10], ["Declined", 20]])], ["Notes", string_type], ["Created", class_type("System.DateTimeOffset")]]);
}

export function ShiftPostCheckItemDtoModule_ofShiftPostCheckItem(shiftPostCheckItem) {
    return new ShiftPostCheckItemDto(shiftPostCheckItem.Id, shiftPostCheckItem.Name, shiftPostCheckItem.Description, shiftPostCheckItem.Priority, shiftPostCheckItem.Status, shiftPostCheckItem.Notes, shiftPostCheckItem.Created);
}

export function ShiftPostCheckItemDtoModule_toShiftPostCheckItem(shiftPostCheckItem) {
    return new ShiftPostCheckItem(shiftPostCheckItem.Id, shiftPostCheckItem.Name, shiftPostCheckItem.Description, shiftPostCheckItem.Priority, shiftPostCheckItem.Status, shiftPostCheckItem.Notes, shiftPostCheckItem.Created);
}

export class ShiftDto extends Record {
    constructor(Id, UserId, OrgId, JobId, JobTitle, JobLocation, JobGeofenceRadius, AppVersion, Os, IsShiftStartAndFinishOutsideGeofenceAllowed, IsShiftStartAndFinishOutsideScheduleAllowed, ShiftScheduleStartTime, ShiftScheduleFinishTime, ShiftPreCheckItems, ShiftPostCheckItems, Created, IsFinished, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.OrgId = OrgId;
        this.JobId = JobId;
        this.JobTitle = JobTitle;
        this.JobLocation = JobLocation;
        this.JobGeofenceRadius = (JobGeofenceRadius | 0);
        this.AppVersion = AppVersion;
        this.Os = Os;
        this.IsShiftStartAndFinishOutsideGeofenceAllowed = IsShiftStartAndFinishOutsideGeofenceAllowed;
        this.IsShiftStartAndFinishOutsideScheduleAllowed = IsShiftStartAndFinishOutsideScheduleAllowed;
        this.ShiftScheduleStartTime = ShiftScheduleStartTime;
        this.ShiftScheduleFinishTime = ShiftScheduleFinishTime;
        this.ShiftPreCheckItems = ShiftPreCheckItems;
        this.ShiftPostCheckItems = ShiftPostCheckItems;
        this.Created = Created;
        this.IsFinished = IsFinished;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function ShiftDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftDto", [], ShiftDto, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["JobTitle", string_type], ["JobLocation", LocationDto_$reflection()], ["JobGeofenceRadius", int32_type], ["AppVersion", string_type], ["Os", string_type], ["IsShiftStartAndFinishOutsideGeofenceAllowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleAllowed", bool_type], ["ShiftScheduleStartTime", class_type("System.TimeOnly")], ["ShiftScheduleFinishTime", class_type("System.TimeOnly")], ["ShiftPreCheckItems", list_type(ShiftPreCheckItemDto_$reflection())], ["ShiftPostCheckItems", list_type(ShiftPostCheckItemDto_$reflection())], ["Created", class_type("System.DateTime")], ["IsFinished", bool_type], ["Updated", class_type("System.DateTime")], ["ServerUpdated", class_type("System.Nullable`1", [class_type("System.DateTime")])]]);
}

export function ShiftDtoModule_ofShift(shift) {
    return new ShiftDto(shift.Id, shift.UserId, shift.OrgId, shift.JobId, shift.JobTitle, LocationDtoModule_ofLocationEx(shift.JobLocation), shift.JobGeofenceRadius, shift.AppVersion, shift.Os, shift.IsShiftStartAndFinishOutsideGeofenceAllowed, shift.IsShiftStartAndFinishOutsideScheduleAllowed, shift.ShiftScheduleStartTime, shift.ShiftScheduleFinishTime, map(ShiftPreCheckItemDtoModule_ofShiftPreCheckItem, shift.ShiftPreCheckItems), map(ShiftPostCheckItemDtoModule_ofShiftPostCheckItem, shift.ShiftPostCheckItems), shift.Created, shift.IsFinished, shift.Updated, toNullable(shift.ServerUpdated));
}

export function ShiftDtoModule_toShift(shift) {
    return new Shift(shift.Id, shift.UserId, shift.OrgId, shift.JobId, shift.JobTitle, LocationDtoModule_toLocationEx(shift.JobLocation), shift.JobGeofenceRadius, shift.AppVersion, shift.Os, shift.IsShiftStartAndFinishOutsideGeofenceAllowed, shift.IsShiftStartAndFinishOutsideScheduleAllowed, shift.ShiftScheduleStartTime, shift.ShiftScheduleFinishTime, map(ShiftPreCheckItemDtoModule_toShiftPreCheckItem, shift.ShiftPreCheckItems), map(ShiftPostCheckItemDtoModule_toShiftPostCheckItem, shift.ShiftPostCheckItems), shift.Created, shift.IsFinished, shift.Updated, ofNullable(shift.ServerUpdated));
}

export class ShiftPreCheckItemRawDto extends Record {
    constructor(Id, Name, Description, Priority, Status, Notes, Created) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Status = (Status | 0);
        this.Notes = Notes;
        this.Created = Created;
    }
}

export function ShiftPreCheckItemRawDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftPreCheckItemRawDto", [], ShiftPreCheckItemRawDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Status", int32_type], ["Notes", string_type], ["Created", class_type("System.DateTime")]]);
}

export function ShiftPreCheckItemRawDtoModule_ofShiftPreCheckItem(shiftPreCheckItem) {
    return new ShiftPreCheckItemRawDto(shiftPreCheckItem.Id, shiftPreCheckItem.Name, shiftPreCheckItem.Description, shiftPreCheckItem.Priority, shiftPreCheckItem.Status, shiftPreCheckItem.Notes, toUniversalTime(shiftPreCheckItem.Created));
}

export function ShiftPreCheckItemRawDtoModule_toShiftPreCheckItem(shiftPreCheckItem) {
    return new ShiftPreCheckItem(shiftPreCheckItem.Id, shiftPreCheckItem.Name, shiftPreCheckItem.Description, shiftPreCheckItem.Priority, shiftPreCheckItem.Status, shiftPreCheckItem.Notes, fromDate(shiftPreCheckItem.Created));
}

export class ShiftPostCheckItemRawDto extends Record {
    constructor(Id, Name, Description, Priority, Status, Notes, Created) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Status = (Status | 0);
        this.Notes = Notes;
        this.Created = Created;
    }
}

export function ShiftPostCheckItemRawDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftPostCheckItemRawDto", [], ShiftPostCheckItemRawDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Status", int32_type], ["Notes", string_type], ["Created", class_type("System.DateTime")]]);
}

export function ShiftPostCheckItemRawDtoModule_ofShiftPostCheckItem(shiftPostCheckItem) {
    return new ShiftPostCheckItemRawDto(shiftPostCheckItem.Id, shiftPostCheckItem.Name, shiftPostCheckItem.Description, shiftPostCheckItem.Priority, shiftPostCheckItem.Status, shiftPostCheckItem.Notes, toUniversalTime(shiftPostCheckItem.Created));
}

export function ShiftPostCheckItemRawDtoModule_toShiftPostCheckItem(shiftPostCheckItem) {
    return new ShiftPostCheckItem(shiftPostCheckItem.Id, shiftPostCheckItem.Name, shiftPostCheckItem.Description, shiftPostCheckItem.Priority, shiftPostCheckItem.Status, shiftPostCheckItem.Notes, fromDate(shiftPostCheckItem.Created));
}

export class ShiftRawDto extends Record {
    constructor(Id, UserId, OrgId, JobId, JobTitle, JobLocation, JobGeofenceRadius, AppVersion, Os, IsShiftStartAndFinishOutsideGeofenceAllowed, IsShiftStartAndFinishOutsideScheduleAllowed, ShiftScheduleStartTime, ShiftScheduleFinishTime, ShiftPreCheckItems, ShiftPostCheckItems, Created, IsFinished, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.OrgId = OrgId;
        this.JobId = JobId;
        this.JobTitle = JobTitle;
        this.JobLocation = JobLocation;
        this.JobGeofenceRadius = (JobGeofenceRadius | 0);
        this.AppVersion = AppVersion;
        this.Os = Os;
        this.IsShiftStartAndFinishOutsideGeofenceAllowed = IsShiftStartAndFinishOutsideGeofenceAllowed;
        this.IsShiftStartAndFinishOutsideScheduleAllowed = IsShiftStartAndFinishOutsideScheduleAllowed;
        this.ShiftScheduleStartTime = ShiftScheduleStartTime;
        this.ShiftScheduleFinishTime = ShiftScheduleFinishTime;
        this.ShiftPreCheckItems = ShiftPreCheckItems;
        this.ShiftPostCheckItems = ShiftPostCheckItems;
        this.Created = Created;
        this.IsFinished = IsFinished;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function ShiftRawDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftRawDto", [], ShiftRawDto, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["JobTitle", string_type], ["JobLocation", LocationDto_$reflection()], ["JobGeofenceRadius", int32_type], ["AppVersion", string_type], ["Os", string_type], ["IsShiftStartAndFinishOutsideGeofenceAllowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleAllowed", bool_type], ["ShiftScheduleStartTime", string_type], ["ShiftScheduleFinishTime", string_type], ["ShiftPreCheckItems", list_type(ShiftPreCheckItemRawDto_$reflection())], ["ShiftPostCheckItems", list_type(ShiftPostCheckItemRawDto_$reflection())], ["Created", class_type("System.DateTime")], ["IsFinished", bool_type], ["Updated", class_type("System.DateTime")], ["ServerUpdated", class_type("System.Nullable`1", [class_type("System.DateTime")])]]);
}

export function ShiftRawDtoModule_ofShift(shift) {
    return new ShiftRawDto(shift.Id, shift.UserId, shift.OrgId, shift.JobId, shift.JobTitle, LocationDtoModule_ofLocationEx(shift.JobLocation), shift.JobGeofenceRadius, shift.AppVersion, shift.Os, shift.IsShiftStartAndFinishOutsideGeofenceAllowed, shift.IsShiftStartAndFinishOutsideScheduleAllowed, toString(shift.ShiftScheduleStartTime), toString(shift.ShiftScheduleFinishTime), map(ShiftPreCheckItemRawDtoModule_ofShiftPreCheckItem, shift.ShiftPreCheckItems), map(ShiftPostCheckItemRawDtoModule_ofShiftPostCheckItem, shift.ShiftPostCheckItems), shift.Created, shift.IsFinished, shift.Updated, toNullable(shift.ServerUpdated));
}

export function ShiftRawDtoModule_toShift(shift) {
    return new Shift(shift.Id, shift.UserId, shift.OrgId, shift.JobId, shift.JobTitle, LocationDtoModule_toLocationEx(shift.JobLocation), shift.JobGeofenceRadius, shift.AppVersion, shift.Os, shift.IsShiftStartAndFinishOutsideGeofenceAllowed, shift.IsShiftStartAndFinishOutsideScheduleAllowed, parse(shift.ShiftScheduleStartTime), parse(shift.ShiftScheduleFinishTime), map(ShiftPreCheckItemRawDtoModule_toShiftPreCheckItem, shift.ShiftPreCheckItems), map(ShiftPostCheckItemRawDtoModule_toShiftPostCheckItem, shift.ShiftPostCheckItems), shift.Created, shift.IsFinished, shift.Updated, ofNullable(shift.ServerUpdated));
}

export class ShiftComprehensiveDto extends Record {
    constructor(Id, UserId, Username, OrgId, JobId, JobTitle, JobLocation, JobGeofenceRadius, Created, ShiftStarted, ShiftFinished, IsFinished, AppVersion, ShiftPreCheckItems, ShiftPostCheckItems, Os, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.Username = Username;
        this.OrgId = OrgId;
        this.JobId = JobId;
        this.JobTitle = JobTitle;
        this.JobLocation = JobLocation;
        this.JobGeofenceRadius = (JobGeofenceRadius | 0);
        this.Created = Created;
        this.ShiftStarted = ShiftStarted;
        this.ShiftFinished = ShiftFinished;
        this.IsFinished = IsFinished;
        this.AppVersion = AppVersion;
        this.ShiftPreCheckItems = ShiftPreCheckItems;
        this.ShiftPostCheckItems = ShiftPostCheckItems;
        this.Os = Os;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function ShiftComprehensiveDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftComprehensiveDto", [], ShiftComprehensiveDto, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type], ["OrgId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["JobTitle", string_type], ["JobLocation", LocationDto_$reflection()], ["JobGeofenceRadius", int32_type], ["Created", class_type("System.DateTime")], ["ShiftStarted", option_type(class_type("System.DateTime"))], ["ShiftFinished", option_type(class_type("System.DateTime"))], ["IsFinished", bool_type], ["AppVersion", string_type], ["ShiftPreCheckItems", list_type(ShiftPreCheckItem_$reflection())], ["ShiftPostCheckItems", list_type(ShiftPostCheckItem_$reflection())], ["Os", string_type], ["Updated", class_type("System.DateTime")], ["ServerUpdated", option_type(class_type("System.DateTime"))]]);
}

export function ShiftComprehensiveDtoModule_ofShift(shift) {
    return new ShiftComprehensiveDto(shift.Id, shift.UserId, shift.Username, shift.OrgId, shift.JobId, shift.JobTitle, LocationDtoModule_ofLocationEx(shift.JobLocation), shift.JobGeofenceRadius, shift.Created, shift.ShiftStarted, shift.ShiftFinished, shift.IsFinished, shift.AppVersion, shift.ShiftPreCheckItems, shift.ShiftPostCheckItems, shift.Os, shift.Updated, shift.ServerUpdated);
}

export function ShiftComprehensiveDtoModule_toShift(shift) {
    return new ShiftComprehensive(shift.Id, shift.UserId, shift.Username, shift.OrgId, shift.JobId, shift.JobTitle, LocationDtoModule_toLocationEx(shift.JobLocation), shift.JobGeofenceRadius, shift.Created, shift.ShiftStarted, shift.ShiftFinished, shift.IsFinished, shift.AppVersion, shift.ShiftPreCheckItems, shift.ShiftPostCheckItems, shift.Os, shift.Updated, shift.ServerUpdated);
}

export class ShiftLogDto extends Record {
    constructor(Id, ShiftId, GroupId, Version, ShiftLogType, Comment$, Location, DistanceFromJobLocation, Created, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.ShiftId = ShiftId;
        this.GroupId = GroupId;
        this.Version = (Version | 0);
        this.ShiftLogType = ShiftLogType;
        this.Comment = Comment$;
        this.Location = Location;
        this.DistanceFromJobLocation = (DistanceFromJobLocation | 0);
        this.Created = Created;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function ShiftLogDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftLogDto", [], ShiftLogDto, () => [["Id", class_type("System.Guid")], ["ShiftId", class_type("System.Guid")], ["GroupId", class_type("System.Guid")], ["Version", int32_type], ["ShiftLogType", ShiftLogType_$reflection()], ["Comment", string_type], ["Location", LocationDto_$reflection()], ["DistanceFromJobLocation", int32_type], ["Created", class_type("System.DateTime")], ["Updated", class_type("System.DateTime")], ["ServerUpdated", option_type(class_type("System.DateTime"))]]);
}

export function ShiftLogDtoModule_ofShiftLog(shiftLog) {
    return new ShiftLogDto(shiftLog.Id, shiftLog.ShiftId, shiftLog.GroupId, shiftLog.Version, shiftLog.ShiftLogType, shiftLog.Comment, LocationDtoModule_ofLocationEx(shiftLog.Location), shiftLog.DistanceFromJobLocation, shiftLog.Created, shiftLog.Updated, shiftLog.ServerUpdated);
}

export function ShiftLogDtoModule_toShiftLog(shiftLog) {
    return new ShiftLog(shiftLog.Id, shiftLog.ShiftId, shiftLog.GroupId, shiftLog.Version, shiftLog.ShiftLogType, shiftLog.Comment, LocationDtoModule_toLocationEx(shiftLog.Location), shiftLog.DistanceFromJobLocation, shiftLog.Created, shiftLog.Updated, shiftLog.ServerUpdated);
}

export class ShiftLogRawDto extends Record {
    constructor(Id, ShiftId, GroupId, Version, ShiftLogType, ShiftLogTypeTime, Comment$, Latitude, Longitude, DistanceFromJobLocation, Created, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.ShiftId = ShiftId;
        this.GroupId = GroupId;
        this.Version = (Version | 0);
        this.ShiftLogType = (ShiftLogType | 0);
        this.ShiftLogTypeTime = ShiftLogTypeTime;
        this.Comment = Comment$;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.DistanceFromJobLocation = (DistanceFromJobLocation | 0);
        this.Created = Created;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function ShiftLogRawDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftLogRawDto", [], ShiftLogRawDto, () => [["Id", class_type("System.Guid")], ["ShiftId", class_type("System.Guid")], ["GroupId", class_type("System.Guid")], ["Version", int32_type], ["ShiftLogType", int32_type], ["ShiftLogTypeTime", class_type("System.DateTime")], ["Comment", string_type], ["Latitude", decimal_type], ["Longitude", decimal_type], ["DistanceFromJobLocation", int32_type], ["Created", class_type("System.DateTime")], ["Updated", class_type("System.DateTime")], ["ServerUpdated", class_type("System.Nullable`1", [class_type("System.DateTime")])]]);
}

export function ShiftLogRawDtoModule_ofShiftLog(shiftLog) {
    const patternInput = ShiftLogTypeModule_toInt(shiftLog.ShiftLogType);
    return new ShiftLogRawDto(shiftLog.Id, shiftLog.ShiftId, shiftLog.GroupId, shiftLog.Version, patternInput[0], defaultArg(patternInput[1], minValue()), shiftLog.Comment, shiftLog.Location.Latitude, shiftLog.Location.Longitude, shiftLog.DistanceFromJobLocation, shiftLog.Created, shiftLog.Updated, toNullable(shiftLog.ServerUpdated));
}

export function ShiftLogRawDtoModule_toShiftLog(shiftLog) {
    return new ShiftLog(shiftLog.Id, shiftLog.ShiftId, shiftLog.GroupId, shiftLog.Version, ShiftLogTypeModule_ofInt(shiftLog.ShiftLogType, shiftLog.ShiftLogTypeTime), shiftLog.Comment, new Location(shiftLog.Latitude, shiftLog.Longitude, undefined), shiftLog.DistanceFromJobLocation, shiftLog.Created, shiftLog.Updated, ofNullable(shiftLog.ServerUpdated));
}

export class JobTimesheetDetailDto extends Record {
    constructor(Id, TimesheetRunId, JobId, Title, Address) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.JobId = JobId;
        this.Title = Title;
        this.Address = Address;
    }
}

export function JobTimesheetDetailDto_$reflection() {
    return record_type("Domain.Dto.Shift.JobTimesheetDetailDto", [], JobTimesheetDetailDto, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["Title", string_type], ["Address", AddressDto_$reflection()]]);
}

export function JobTimesheetDetailDtoModule_ofJobTimesheetDetail(jobTimesheetDetail) {
    return new JobTimesheetDetailDto(jobTimesheetDetail.Id, jobTimesheetDetail.TimesheetRunId, jobTimesheetDetail.JobId, jobTimesheetDetail.Title, AddressDtoModule_ofAddress(jobTimesheetDetail.Address));
}

export function JobTimesheetDetailDtoModule_toJobTimesheetDetail(jobTimesheetDetail) {
    return new TimesheetJob(jobTimesheetDetail.Id, jobTimesheetDetail.TimesheetRunId, jobTimesheetDetail.JobId, jobTimesheetDetail.Title, AddressDtoModule_toAddress(undefined, jobTimesheetDetail.Address));
}

export class TimesheetRunDto extends Record {
    constructor(Id, RunNumber, OrgId, DateRange, Jobs, TimesheetRunStatus, Created, Canceled, Approved) {
        super();
        this.Id = Id;
        this.RunNumber = RunNumber;
        this.OrgId = OrgId;
        this.DateRange = DateRange;
        this.Jobs = Jobs;
        this.TimesheetRunStatus = (TimesheetRunStatus | 0);
        this.Created = Created;
        this.Canceled = Canceled;
        this.Approved = Approved;
    }
}

export function TimesheetRunDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetRunDto", [], TimesheetRunDto, () => [["Id", class_type("System.Guid")], ["RunNumber", string_type], ["OrgId", class_type("System.Guid")], ["DateRange", DateRange_$reflection()], ["Jobs", list_type(JobTimesheetDetailDto_$reflection())], ["TimesheetRunStatus", enum_type("Domain.Entity.Shift.TimesheetRunStatus", int32_type, [["NoState", 0], ["Created", 10], ["Canceled", 20], ["Approved", 30]])], ["Created", anonRecord_type(["CreatedBy", class_type("System.Guid")], ["DateCreated", class_type("System.DateTime")])], ["Canceled", option_type(anonRecord_type(["CanceledBy", class_type("System.Guid")], ["DateCanceled", class_type("System.DateTime")]))], ["Approved", option_type(anonRecord_type(["ApprovedBy", class_type("System.Guid")], ["DateApproved", class_type("System.DateTime")]))]]);
}

export function TimesheetRunDtoModule_ofTimesheetRun(timesheetRun) {
    return new TimesheetRunDto(timesheetRun.Id, timesheetRun.RunNumber, timesheetRun.OrgId, timesheetRun.DateRange, map(JobTimesheetDetailDtoModule_ofJobTimesheetDetail, timesheetRun.Jobs), timesheetRun.TimesheetRunStatus, timesheetRun.Created, timesheetRun.Canceled, timesheetRun.Approved);
}

export function TimesheetRunDtoModule_toTimesheetRun(timesheetRun) {
    return new TimesheetRun(timesheetRun.Id, timesheetRun.RunNumber, timesheetRun.OrgId, timesheetRun.DateRange, map(JobTimesheetDetailDtoModule_toJobTimesheetDetail, timesheetRun.Jobs), timesheetRun.TimesheetRunStatus, timesheetRun.Created, timesheetRun.Canceled, timesheetRun.Approved);
}

export class TimesheetDto extends Record {
    constructor(Id, TimesheetRunId, ShiftId, TotalWorkDuration, TotalWorkDurationAdjustment, IsTotalWorkDurationAdjusted, TotalBreakDuration, TotalBreakDurationAdjustment, IsTotalBreakDurationAdjusted, IsApproved, SupervisorComment, WorkerShiftComments) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.ShiftId = ShiftId;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalWorkDurationAdjustment = TotalWorkDurationAdjustment;
        this.IsTotalWorkDurationAdjusted = IsTotalWorkDurationAdjusted;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalBreakDurationAdjustment = TotalBreakDurationAdjustment;
        this.IsTotalBreakDurationAdjusted = IsTotalBreakDurationAdjusted;
        this.IsApproved = IsApproved;
        this.SupervisorComment = SupervisorComment;
        this.WorkerShiftComments = WorkerShiftComments;
    }
}

export function TimesheetDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetDto", [], TimesheetDto, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["ShiftId", class_type("System.Guid")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalWorkDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalWorkDurationAdjusted", bool_type], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalBreakDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalBreakDurationAdjusted", bool_type], ["IsApproved", bool_type], ["SupervisorComment", string_type], ["WorkerShiftComments", string_type]]);
}

export function TimesheetDtoModule_ofTimesheet(timesheet) {
    return new TimesheetDto(timesheet.Id, timesheet.TimesheetRunId, timesheet.ShiftId, timesheet.TotalWorkDuration, timesheet.TotalWorkDurationAdjustment, timesheet.IsTotalWorkDurationAdjusted, timesheet.TotalBreakDuration, timesheet.TotalBreakDurationAdjustment, timesheet.IsTotalBreakDurationAdjusted, timesheet.IsApproved, timesheet.SupervisorComment, timesheet.WorkerShiftComments);
}

export function TimesheetDtoModule_toTimesheet(timesheet) {
    return new Timesheet(timesheet.Id, timesheet.TimesheetRunId, timesheet.ShiftId, timesheet.TotalWorkDuration, timesheet.TotalWorkDurationAdjustment, timesheet.IsTotalWorkDurationAdjusted, timesheet.TotalBreakDuration, timesheet.TotalBreakDurationAdjustment, timesheet.IsTotalBreakDurationAdjusted, timesheet.IsApproved, timesheet.SupervisorComment, timesheet.WorkerShiftComments);
}

export class TimesheetOfUserDto extends Record {
    constructor(Id, TimesheetRunId, TimesheetRunNumber, ShiftId, ShiftStartDate, TotalWorkDuration, TotalWorkDurationAdjustment, IsTotalWorkDurationAdjusted, TotalBreakDuration, TotalBreakDurationAdjustment, IsTotalBreakDurationAdjusted, IsApproved, ApprovedBy, ApproveDate, SupervisorComment, WorkerShiftComments, OvertimeAfterWorkHours, Leaves, Holidays) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.TimesheetRunNumber = TimesheetRunNumber;
        this.ShiftId = ShiftId;
        this.ShiftStartDate = ShiftStartDate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalWorkDurationAdjustment = TotalWorkDurationAdjustment;
        this.IsTotalWorkDurationAdjusted = IsTotalWorkDurationAdjusted;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalBreakDurationAdjustment = TotalBreakDurationAdjustment;
        this.IsTotalBreakDurationAdjusted = IsTotalBreakDurationAdjusted;
        this.IsApproved = IsApproved;
        this.ApprovedBy = ApprovedBy;
        this.ApproveDate = ApproveDate;
        this.SupervisorComment = SupervisorComment;
        this.WorkerShiftComments = WorkerShiftComments;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.Leaves = Leaves;
        this.Holidays = Holidays;
    }
}

export function TimesheetOfUserDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetOfUserDto", [], TimesheetOfUserDto, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["TimesheetRunNumber", string_type], ["ShiftId", class_type("System.Guid")], ["ShiftStartDate", class_type("System.DateTime")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalWorkDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalWorkDurationAdjusted", bool_type], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalBreakDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalBreakDurationAdjusted", bool_type], ["IsApproved", bool_type], ["ApprovedBy", option_type(string_type)], ["ApproveDate", option_type(class_type("System.DateTime"))], ["SupervisorComment", string_type], ["WorkerShiftComments", string_type], ["OvertimeAfterWorkHours", class_type("System.TimeSpan")], ["Leaves", list_type(LeaveRequestDto_$reflection())], ["Holidays", list_type(Holiday_$reflection())]]);
}

export function TimesheetOfUserDtoModule_ofTimesheet(timesheet) {
    return new TimesheetOfUserDto(timesheet.Id, timesheet.TimesheetRunId, timesheet.TimesheetRunNumber, timesheet.ShiftId, timesheet.ShiftStartDate, timesheet.TotalWorkDuration, timesheet.TotalWorkDurationAdjustment, timesheet.IsTotalWorkDurationAdjusted, timesheet.TotalBreakDuration, timesheet.TotalBreakDurationAdjustment, timesheet.IsTotalBreakDurationAdjusted, timesheet.IsApproved, timesheet.ApprovedBy, timesheet.ApproveDate, timesheet.SupervisorComment, timesheet.WorkerShiftComments, timesheet.OvertimeAfterWorkHours, map(LeaveRequestDtoModule_ofLeaveRequest, timesheet.Leaves), timesheet.Holidays);
}

export function TimesheetOfUserDtoModule_toTimesheet(timesheet) {
    return new TimesheetOfUser(timesheet.Id, timesheet.TimesheetRunId, timesheet.TimesheetRunNumber, timesheet.ShiftId, timesheet.ShiftStartDate, timesheet.TotalWorkDuration, timesheet.TotalWorkDurationAdjustment, timesheet.IsTotalWorkDurationAdjusted, timesheet.TotalBreakDuration, timesheet.TotalBreakDurationAdjustment, timesheet.IsTotalBreakDurationAdjusted, timesheet.IsApproved, timesheet.ApprovedBy, timesheet.ApproveDate, timesheet.SupervisorComment, timesheet.WorkerShiftComments, timesheet.OvertimeAfterWorkHours, map(LeaveRequestDtoModule_toLeaveRequest, timesheet.Leaves), timesheet.Holidays);
}

export class TimesheetComprehensiveDto extends Record {
    constructor(Id, TimesheetRunId, ShiftId, ShiftStart, ShiftFinish, JobId, UserId, Username, HourlyRate, ChargeOutRate, TotalWorkDuration, TotalWorkDurationAdjustment, IsTotalWorkDurationAdjusted, TotalBreakDuration, TotalBreakDurationAdjustment, IsTotalBreakDurationAdjusted, OvertimeAfterWorkHours, IsApproved, SupervisorComment, WorkerShiftComments, IsStartOrFinishOutsideJobGeofence, EmploymentType, WeeklyFulltimeWorkHours) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.ShiftId = ShiftId;
        this.ShiftStart = ShiftStart;
        this.ShiftFinish = ShiftFinish;
        this.JobId = JobId;
        this.UserId = UserId;
        this.Username = Username;
        this.HourlyRate = HourlyRate;
        this.ChargeOutRate = ChargeOutRate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalWorkDurationAdjustment = TotalWorkDurationAdjustment;
        this.IsTotalWorkDurationAdjusted = IsTotalWorkDurationAdjusted;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalBreakDurationAdjustment = TotalBreakDurationAdjustment;
        this.IsTotalBreakDurationAdjusted = IsTotalBreakDurationAdjusted;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.IsApproved = IsApproved;
        this.SupervisorComment = SupervisorComment;
        this.WorkerShiftComments = WorkerShiftComments;
        this.IsStartOrFinishOutsideJobGeofence = IsStartOrFinishOutsideJobGeofence;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
    }
}

export function TimesheetComprehensiveDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetComprehensiveDto", [], TimesheetComprehensiveDto, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["ShiftId", class_type("System.Guid")], ["ShiftStart", option_type(class_type("System.DateTime"))], ["ShiftFinish", option_type(class_type("System.DateTime"))], ["JobId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type], ["HourlyRate", decimal_type], ["ChargeOutRate", decimal_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalWorkDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalWorkDurationAdjusted", bool_type], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalBreakDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalBreakDurationAdjusted", bool_type], ["OvertimeAfterWorkHours", class_type("System.TimeSpan")], ["IsApproved", bool_type], ["SupervisorComment", string_type], ["WorkerShiftComments", list_type(string_type)], ["IsStartOrFinishOutsideJobGeofence", bool_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))]]);
}

export function TimesheetComprehensiveDtoModule_ofTimesheet(timesheet) {
    return new TimesheetComprehensiveDto(timesheet.Id, timesheet.TimesheetRunId, timesheet.ShiftId, timesheet.ShiftStart, timesheet.ShiftFinish, timesheet.JobId, timesheet.UserId, timesheet.Username, timesheet.HourlyRate, timesheet.ChargeOutRate, timesheet.TotalWorkDuration, timesheet.TotalWorkDurationAdjustment, timesheet.IsTotalWorkDurationAdjusted, timesheet.TotalBreakDuration, timesheet.TotalBreakDurationAdjustment, timesheet.IsTotalBreakDurationAdjusted, timesheet.OvertimeAfterWorkHours, timesheet.IsApproved, timesheet.SupervisorComment, timesheet.WorkerShiftComments, timesheet.IsStartOrFinishOutsideJobGeofence, timesheet.EmploymentType, timesheet.WeeklyFulltimeWorkHours);
}

export function TimesheetComprehensiveDtoModule_toTimesheet(timesheet) {
    return new TimesheetComprehensive(timesheet.Id, timesheet.TimesheetRunId, timesheet.ShiftId, timesheet.ShiftStart, timesheet.ShiftFinish, timesheet.JobId, timesheet.UserId, timesheet.Username, timesheet.HourlyRate, timesheet.ChargeOutRate, timesheet.TotalWorkDuration, timesheet.TotalWorkDurationAdjustment, timesheet.IsTotalWorkDurationAdjusted, timesheet.TotalBreakDuration, timesheet.TotalBreakDurationAdjustment, timesheet.IsTotalBreakDurationAdjusted, timesheet.OvertimeAfterWorkHours, timesheet.IsApproved, timesheet.SupervisorComment, timesheet.WorkerShiftComments, timesheet.IsStartOrFinishOutsideJobGeofence, timesheet.EmploymentType, timesheet.WeeklyFulltimeWorkHours);
}

export class TimesheetShiftDto extends Record {
    constructor(Shift, ShiftLogs, TotalWorkDuration, TotalBreakDuration) {
        super();
        this.Shift = Shift;
        this.ShiftLogs = ShiftLogs;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalBreakDuration = TotalBreakDuration;
    }
}

export function TimesheetShiftDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetShiftDto", [], TimesheetShiftDto, () => [["Shift", ShiftDto_$reflection()], ["ShiftLogs", list_type(ShiftLogDto_$reflection())], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalBreakDuration", class_type("System.TimeSpan")]]);
}

export function TimesheetShiftDtoModule_ofTimesheetShift(timesheetShift) {
    return new TimesheetShiftDto(ShiftDtoModule_ofShift(timesheetShift.Shift), map(ShiftLogDtoModule_ofShiftLog, timesheetShift.ShiftLogs), timesheetShift.TotalWorkDuration, timesheetShift.TotalBreakDuration);
}

export function TimesheetShiftDtoModule_toTimesheetShift(timesheetShift) {
    return new TimesheetShift(ShiftDtoModule_toShift(timesheetShift.Shift), map(ShiftLogDtoModule_toShiftLog, timesheetShift.ShiftLogs), timesheetShift.TotalWorkDuration, timesheetShift.TotalBreakDuration);
}

export class PaymentTimesheetRunDto extends Record {
    constructor(TimesheetRun, Timesheets) {
        super();
        this.TimesheetRun = TimesheetRun;
        this.Timesheets = Timesheets;
    }
}

export function PaymentTimesheetRunDto_$reflection() {
    return record_type("Domain.Dto.Shift.PaymentTimesheetRunDto", [], PaymentTimesheetRunDto, () => [["TimesheetRun", TimesheetRunDto_$reflection()], ["Timesheets", list_type(TimesheetComprehensiveDto_$reflection())]]);
}

export class PaymentRunDto extends Record {
    constructor(Id, OrgId, RunNumber, PaymentRunStatus, Created, Canceled, Paid, PayPeriodStart, PayPeriodFinish) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.RunNumber = RunNumber;
        this.PaymentRunStatus = (PaymentRunStatus | 0);
        this.Created = Created;
        this.Canceled = Canceled;
        this.Paid = Paid;
        this.PayPeriodStart = PayPeriodStart;
        this.PayPeriodFinish = PayPeriodFinish;
    }
}

export function PaymentRunDto_$reflection() {
    return record_type("Domain.Dto.Shift.PaymentRunDto", [], PaymentRunDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["RunNumber", string_type], ["PaymentRunStatus", enum_type("Domain.Entity.Shift.PaymentRunStatus", int32_type, [["NoState", 0], ["Created", 10], ["Canceled", 20], ["Paid", 30]])], ["Created", anonRecord_type(["CreatedBy", class_type("System.Guid")], ["DateCreated", class_type("System.DateTime")])], ["Canceled", option_type(anonRecord_type(["CanceledBy", class_type("System.Guid")], ["DateCanceled", class_type("System.DateTime")]))], ["Paid", option_type(anonRecord_type(["DatePaid", class_type("System.DateTime")], ["PaidBy", class_type("System.Guid")]))], ["PayPeriodStart", class_type("System.DateTimeOffset")], ["PayPeriodFinish", class_type("System.DateTimeOffset")]]);
}

export function PaymentRunDtoModule_ofPaymentRun(paymentRun) {
    return new PaymentRunDto(paymentRun.Id, paymentRun.OrgId, paymentRun.RunNumber, paymentRun.PaymentRunStatus, paymentRun.Created, paymentRun.Canceled, paymentRun.Paid, paymentRun.PayPeriodStart, paymentRun.PayPeriodFinish);
}

export function PaymentRunDtoModule_toPaymentRun(paymentRun) {
    return new PaymentRun(paymentRun.Id, paymentRun.OrgId, paymentRun.RunNumber, paymentRun.PaymentRunStatus, paymentRun.Created, paymentRun.Canceled, paymentRun.Paid, paymentRun.PayPeriodStart, paymentRun.PayPeriodFinish);
}

export class PaymentRunTimesheetRunDto extends Record {
    constructor(Id, PaymentRunId, TimesheetRunId) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.TimesheetRunId = TimesheetRunId;
    }
}

export function PaymentRunTimesheetRunDto_$reflection() {
    return record_type("Domain.Dto.Shift.PaymentRunTimesheetRunDto", [], PaymentRunTimesheetRunDto, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")]]);
}

export function PaymentRunTimesheetRunDtoModule_ofPaymentRunTimesheetRun(paymentRunTimesheetRun) {
    return new PaymentRunTimesheetRunDto(paymentRunTimesheetRun.Id, paymentRunTimesheetRun.PaymentRunId, paymentRunTimesheetRun.TimesheetRunId);
}

export function PaymentRunTimesheetRunDtoModule_toPaymentRunTimesheetRun(paymentRunTimesheetRun) {
    return new PaymentRunTimesheetRun(paymentRunTimesheetRun.Id, paymentRunTimesheetRun.PaymentRunId, paymentRunTimesheetRun.TimesheetRunId);
}

export class PaymentDto extends Record {
    constructor(Id, PaymentRunId, UserId, HourlyRatePaid, OvertimeRatePaid, DailyWorkDuration, WorkDuration, WorkPay, OvertimeDuration, OvertimeAfterWorkHours, OvertimePay, TotalPay, TotalDaysWorked, TotalLeaveDays, TotalStatDays, DateCreated, Comment$, EmploymentType, WeeklyFulltimeWorkHours) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.UserId = UserId;
        this.HourlyRatePaid = HourlyRatePaid;
        this.OvertimeRatePaid = OvertimeRatePaid;
        this.DailyWorkDuration = DailyWorkDuration;
        this.WorkDuration = WorkDuration;
        this.WorkPay = WorkPay;
        this.OvertimeDuration = OvertimeDuration;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.OvertimePay = OvertimePay;
        this.TotalPay = TotalPay;
        this.TotalDaysWorked = (TotalDaysWorked | 0);
        this.TotalLeaveDays = (TotalLeaveDays | 0);
        this.TotalStatDays = (TotalStatDays | 0);
        this.DateCreated = DateCreated;
        this.Comment = Comment$;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
    }
}

export function PaymentDto_$reflection() {
    return record_type("Domain.Dto.Shift.PaymentDto", [], PaymentDto, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["HourlyRatePaid", decimal_type], ["OvertimeRatePaid", decimal_type], ["DailyWorkDuration", class_type("System.TimeSpan")], ["WorkDuration", class_type("System.TimeSpan")], ["WorkPay", decimal_type], ["OvertimeDuration", class_type("System.TimeSpan")], ["OvertimeAfterWorkHours", decimal_type], ["OvertimePay", decimal_type], ["TotalPay", decimal_type], ["TotalDaysWorked", int32_type], ["TotalLeaveDays", int32_type], ["TotalStatDays", int32_type], ["DateCreated", class_type("System.DateTime")], ["Comment", string_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))]]);
}

export function PaymentDtoModule_ofPayment(payment) {
    return new PaymentDto(payment.Id, payment.PaymentRunId, payment.UserId, payment.HourlyRatePaid, payment.OvertimeRatePaid, payment.DailyWorkDuration, payment.WorkDuration, payment.WorkPay, payment.OvertimeDuration, payment.OvertimeAfterWorkHours, payment.OvertimePay, payment.TotalPay, payment.TotalDaysWorked, payment.TotalLeaveDays, payment.TotalStatDays, payment.DateCreated, payment.Comment, payment.EmploymentType, payment.WeeklyFulltimeWorkHours);
}

export function PaymentDtoModule_toPayment(payment) {
    return new Payment(payment.Id, payment.PaymentRunId, payment.UserId, payment.HourlyRatePaid, payment.OvertimeRatePaid, payment.DailyWorkDuration, payment.WorkDuration, payment.WorkPay, payment.OvertimeDuration, payment.OvertimeAfterWorkHours, payment.OvertimePay, payment.TotalPay, payment.TotalDaysWorked, payment.TotalLeaveDays, payment.TotalStatDays, payment.DateCreated, payment.Comment, payment.EmploymentType, payment.WeeklyFulltimeWorkHours);
}

export class PaymentComprehensiveDto extends Record {
    constructor(Id, PaymentRunId, UserId, Username, FirstName, MiddleName, LastName, HourlyRatePaid, OvertimeRatePaid, DailyWorkDuration, PayrollSystemInfo, WorkDuration, WorkPay, OvertimeDuration, OvertimeAfterWorkHours, OvertimePay, TotalPay, TotalDaysWorked, TotalLeaveDays, TotalStatDays, DateCreated, Comment$, EmploymentType, WeeklyFulltimeWorkHours, PayPeriodStart, PayPeriodFinish) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.UserId = UserId;
        this.Username = Username;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.HourlyRatePaid = HourlyRatePaid;
        this.OvertimeRatePaid = OvertimeRatePaid;
        this.DailyWorkDuration = DailyWorkDuration;
        this.PayrollSystemInfo = PayrollSystemInfo;
        this.WorkDuration = WorkDuration;
        this.WorkPay = WorkPay;
        this.OvertimeDuration = OvertimeDuration;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.OvertimePay = OvertimePay;
        this.TotalPay = TotalPay;
        this.TotalDaysWorked = (TotalDaysWorked | 0);
        this.TotalLeaveDays = (TotalLeaveDays | 0);
        this.TotalStatDays = (TotalStatDays | 0);
        this.DateCreated = DateCreated;
        this.Comment = Comment$;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
        this.PayPeriodStart = PayPeriodStart;
        this.PayPeriodFinish = PayPeriodFinish;
    }
}

export function PaymentComprehensiveDto_$reflection() {
    return record_type("Domain.Dto.Shift.PaymentComprehensiveDto", [], PaymentComprehensiveDto, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["HourlyRatePaid", decimal_type], ["OvertimeRatePaid", decimal_type], ["DailyWorkDuration", class_type("System.TimeSpan")], ["PayrollSystemInfo", option_type(PayrollSystemInfo_$reflection())], ["WorkDuration", class_type("System.TimeSpan")], ["WorkPay", decimal_type], ["OvertimeDuration", class_type("System.TimeSpan")], ["OvertimeAfterWorkHours", decimal_type], ["OvertimePay", decimal_type], ["TotalPay", decimal_type], ["TotalDaysWorked", int32_type], ["TotalLeaveDays", int32_type], ["TotalStatDays", int32_type], ["DateCreated", class_type("System.DateTime")], ["Comment", string_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))], ["PayPeriodStart", class_type("System.DateTimeOffset")], ["PayPeriodFinish", class_type("System.DateTimeOffset")]]);
}

export function PaymentComprehensiveDtoModule_ofPayment(payment) {
    return new PaymentComprehensiveDto(payment.Id, payment.PaymentRunId, payment.UserId, payment.Username, payment.FirstName, payment.MiddleName, payment.LastName, payment.HourlyRatePaid, payment.OvertimeRatePaid, payment.DailyWorkDuration, payment.PayrollSystemInfo, payment.WorkDuration, payment.WorkPay, payment.OvertimeDuration, payment.OvertimeAfterWorkHours, payment.OvertimePay, payment.TotalPay, payment.TotalDaysWorked, payment.TotalLeaveDays, payment.TotalStatDays, payment.DateCreated, payment.Comment, payment.EmploymentType, payment.WeeklyFulltimeWorkHours, payment.PayPeriodStart, payment.PayPeriodFinish);
}

export function PaymentComprehensiveDtoModule_toPayment(payment) {
    return new PaymentComprehensive(payment.Id, payment.PaymentRunId, payment.UserId, payment.Username, payment.FirstName, payment.MiddleName, payment.LastName, payment.HourlyRatePaid, payment.OvertimeRatePaid, payment.DailyWorkDuration, payment.PayrollSystemInfo, payment.WorkDuration, payment.WorkPay, payment.OvertimeDuration, payment.OvertimeAfterWorkHours, payment.OvertimePay, payment.TotalPay, payment.TotalDaysWorked, payment.TotalLeaveDays, payment.TotalStatDays, payment.DateCreated, payment.Comment, payment.EmploymentType, payment.WeeklyFulltimeWorkHours, payment.PayPeriodStart, payment.PayPeriodFinish);
}

export class HolidayDto extends Record {
    constructor(Id, OrgId, Name, Date$, IsActive) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.Name = Name;
        this.Date = Date$;
        this.IsActive = IsActive;
    }
}

export function HolidayDto_$reflection() {
    return record_type("Domain.Dto.Shift.HolidayDto", [], HolidayDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Name", string_type], ["Date", class_type("System.DateTimeOffset")], ["IsActive", bool_type]]);
}

export function HolidayDtoModule_ofHoliday(holiday) {
    return new HolidayDto(holiday.Id, holiday.OrgId, holiday.Name, holiday.Date, holiday.IsActive);
}

export function HolidayDtoModule_toHoliday(holiday) {
    return new Holiday(holiday.Id, holiday.OrgId, holiday.Name, holiday.Date, holiday.IsActive);
}

export class ShiftDetailDto extends Record {
    constructor(Shift, ShiftLogs, TotalWorkDuration, TotalBreakDuration) {
        super();
        this.Shift = Shift;
        this.ShiftLogs = ShiftLogs;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalBreakDuration = TotalBreakDuration;
    }
}

export function ShiftDetailDto_$reflection() {
    return record_type("Domain.Dto.Shift.ShiftDetailDto", [], ShiftDetailDto, () => [["Shift", ShiftComprehensiveDto_$reflection()], ["ShiftLogs", list_type(ShiftLogDto_$reflection())], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalBreakDuration", class_type("System.TimeSpan")]]);
}

export function ShiftDetailDtoModule_ofShiftDetail(shiftDetail) {
    return new ShiftDetailDto(ShiftComprehensiveDtoModule_ofShift(shiftDetail.Shift), map(ShiftLogDtoModule_ofShiftLog, shiftDetail.ShiftLogs), shiftDetail.TotalWorkDuration, shiftDetail.TotalBreakDuration);
}

export function ShiftDetailDtoModule_toShiftDetail(shiftDetail) {
    return new ShiftDetail(ShiftComprehensiveDtoModule_toShift(shiftDetail.Shift), map(ShiftLogDtoModule_toShiftLog, shiftDetail.ShiftLogs), shiftDetail.TotalWorkDuration, shiftDetail.TotalBreakDuration);
}

export class TimesheetRunUserSummaryDto extends Record {
    constructor(Id, TimesheetRunId, UserId, JobId, TotalWorkDuration, TotalBreakDuration, TotalOvertimeDuration) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.UserId = UserId;
        this.JobId = JobId;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function TimesheetRunUserSummaryDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetRunUserSummaryDto", [], TimesheetRunUserSummaryDto, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export class TimesheetRunUserSummaryReportDto extends Record {
    constructor(Job, TotalWorkDuration, TotalOvertimeDuration) {
        super();
        this.Job = Job;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function TimesheetRunUserSummaryReportDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetRunUserSummaryReportDto", [], TimesheetRunUserSummaryReportDto, () => [["Job", string_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export function TimesheetRunUserSummaryReportDtoModule_ofTimesheetRunUserSummaryReport(timesheetRunUserSummaryReport) {
    return new TimesheetRunUserSummaryReportDto(timesheetRunUserSummaryReport.JobName, timesheetRunUserSummaryReport.TotalWorkDuration, timesheetRunUserSummaryReport.TotalOvertimeDuration);
}

export function TimesheetRunUserSummaryReportDtoModule_toTimesheetRunUserSummaryReport(timesheetRunUserSummaryReport) {
    return new TimesheetRunUserSummaryReport(timesheetRunUserSummaryReport.Job, timesheetRunUserSummaryReport.TotalWorkDuration, timesheetRunUserSummaryReport.TotalOvertimeDuration);
}

export class TimesheetReportByWorkerDto extends Record {
    constructor(JobName, WorkerName, TotalWorkDuration, TotalOvertimeDuration) {
        super();
        this.JobName = JobName;
        this.WorkerName = WorkerName;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function TimesheetReportByWorkerDto_$reflection() {
    return record_type("Domain.Dto.Shift.TimesheetReportByWorkerDto", [], TimesheetReportByWorkerDto, () => [["JobName", string_type], ["WorkerName", string_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export function TimesheetReportByWorkerDtoModule_ofTimesheetReportByWorker(timesheetReportByWorker) {
    return new TimesheetReportByWorkerDto(timesheetReportByWorker.JobName, timesheetReportByWorker.WorkerName, timesheetReportByWorker.TotalWorkDuration, timesheetReportByWorker.TotalOvertimeDuration);
}

export function TimesheetReportByWorkerDtoModule_toTimesheetReportByWorker(timesheetReportByWorker) {
    return new TimesheetReportByWorker(timesheetReportByWorker.JobName, timesheetReportByWorker.WorkerName, timesheetReportByWorker.TotalWorkDuration, timesheetReportByWorker.TotalOvertimeDuration);
}

export class PaymentRunComprehensiveDto extends Record {
    constructor(Id, OrgId, RunNumber, PaymentRunStatus, Created, Canceled, Paid, TimesheetRuns, PayPeriodStart, PayPeriodFinish) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.RunNumber = RunNumber;
        this.PaymentRunStatus = (PaymentRunStatus | 0);
        this.Created = Created;
        this.Canceled = Canceled;
        this.Paid = Paid;
        this.TimesheetRuns = TimesheetRuns;
        this.PayPeriodStart = PayPeriodStart;
        this.PayPeriodFinish = PayPeriodFinish;
    }
}

export function PaymentRunComprehensiveDto_$reflection() {
    return record_type("Domain.Dto.Shift.PaymentRunComprehensiveDto", [], PaymentRunComprehensiveDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["RunNumber", string_type], ["PaymentRunStatus", enum_type("Domain.Entity.Shift.PaymentRunStatus", int32_type, [["NoState", 0], ["Created", 10], ["Canceled", 20], ["Paid", 30]])], ["Created", anonRecord_type(["CreatedByName", string_type], ["DateCreated", class_type("System.DateTime")])], ["Canceled", option_type(anonRecord_type(["CanceledByName", string_type], ["DateCanceled", class_type("System.DateTime")]))], ["Paid", option_type(anonRecord_type(["DatePaid", class_type("System.DateTime")], ["PaidByName", string_type]))], ["TimesheetRuns", list_type(TimesheetRunDetail_$reflection())], ["PayPeriodStart", class_type("System.DateTimeOffset")], ["PayPeriodFinish", class_type("System.DateTimeOffset")]]);
}

export function PaymentRunComprehensiveDtoModule_ofPaymentRun(paymentRun) {
    return new PaymentRunComprehensiveDto(paymentRun.Id, paymentRun.OrgId, paymentRun.RunNumber, paymentRun.PaymentRunStatus, paymentRun.Created, paymentRun.Canceled, paymentRun.Paid, paymentRun.TimesheetRuns, paymentRun.PayPeriodStart, paymentRun.PayPeriodFinish);
}

export function PaymentRunComprehensiveDtoModule_toPaymentRun(paymentRun) {
    return new PaymentRunComprehensive(paymentRun.Id, paymentRun.OrgId, paymentRun.RunNumber, paymentRun.PaymentRunStatus, paymentRun.Created, paymentRun.Canceled, paymentRun.Paid, paymentRun.TimesheetRuns, paymentRun.PayPeriodStart, paymentRun.PayPeriodFinish);
}

export class SimproContractorWorkOrderDto extends Record {
    constructor(Id, PaymentRunId, JobId, SimproJobId, SimproJobSectionId, SimproJobCostCentreId, SimproContractorId, Date$, LabourCost, LabourHours, TaxCode, OverheadRate, OverheadCost) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.JobId = JobId;
        this.SimproJobId = (SimproJobId | 0);
        this.SimproJobSectionId = (SimproJobSectionId | 0);
        this.SimproJobCostCentreId = (SimproJobCostCentreId | 0);
        this.SimproContractorId = (SimproContractorId | 0);
        this.Date = Date$;
        this.LabourCost = LabourCost;
        this.LabourHours = LabourHours;
        this.TaxCode = (TaxCode | 0);
        this.OverheadRate = OverheadRate;
        this.OverheadCost = OverheadCost;
    }
}

export function SimproContractorWorkOrderDto_$reflection() {
    return record_type("Domain.Dto.Shift.SimproContractorWorkOrderDto", [], SimproContractorWorkOrderDto, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["SimproJobId", int32_type], ["SimproJobSectionId", int32_type], ["SimproJobCostCentreId", int32_type], ["SimproContractorId", int32_type], ["Date", class_type("System.DateTimeOffset")], ["LabourCost", decimal_type], ["LabourHours", decimal_type], ["TaxCode", int32_type], ["OverheadRate", decimal_type], ["OverheadCost", decimal_type]]);
}

export function SimproContractorWorkOrderDtoModule_ofSimproContractorWorkOrder(simproContractorWorkOrder) {
    return new SimproContractorWorkOrderDto(simproContractorWorkOrder.Id, simproContractorWorkOrder.PaymentRunId, simproContractorWorkOrder.JobId, simproContractorWorkOrder.SimproJobId, simproContractorWorkOrder.SimproJobSectionId, simproContractorWorkOrder.SimproJobCostCentreId, simproContractorWorkOrder.SimproContractorId, simproContractorWorkOrder.Date, simproContractorWorkOrder.LabourCost, simproContractorWorkOrder.LabourHours, simproContractorWorkOrder.TaxCode, simproContractorWorkOrder.OverheadRate, simproContractorWorkOrder.OverheadCost);
}

export function SimproContractorWorkOrderDtoModule_toSimproContractorWorkOrder(simproContractorWorkOrder) {
    return new SimproContractorWorkOrder(simproContractorWorkOrder.Id, simproContractorWorkOrder.PaymentRunId, simproContractorWorkOrder.JobId, simproContractorWorkOrder.SimproJobId, simproContractorWorkOrder.SimproJobSectionId, simproContractorWorkOrder.SimproJobCostCentreId, simproContractorWorkOrder.SimproContractorId, simproContractorWorkOrder.Date, simproContractorWorkOrder.LabourCost, simproContractorWorkOrder.LabourHours, simproContractorWorkOrder.TaxCode, simproContractorWorkOrder.OverheadRate, simproContractorWorkOrder.OverheadCost);
}

