import { Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, int32_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { createInternal } from "./Util.fs.js";

export class SubscriptionCount extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SubscriptionCount"];
    }
}

export function SubscriptionCount_$reflection() {
    return union_type("Domain.Entity.SubscriptionCount.SubscriptionCount", [], SubscriptionCount, () => [[["Item", int32_type]]]);
}

function validations() {
    return singleton([(x) => (x > 5), (_arg) => "Additional Subscription count cannot be greater than 5."]);
}

export function create(subscriptionCount) {
    return createInternal(validations(), subscriptionCount, new SubscriptionCount(subscriptionCount));
}

export function value(_arg) {
    return _arg.fields[0];
}

export const zero = new SubscriptionCount(0);

