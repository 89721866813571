import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { Product_$reflection } from "../../../../Logos.Shared/Domain/Entity/Product.fs.js";
import { union_type, unit_type, class_type, record_type, list_type, string_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { create, zero, SubscriptionCount_$reflection } from "../../../../Logos.Shared/Domain/Entity/SubscriptionCount.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { ofArray, isEmpty, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { cmdOfFunc, cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { cancelSubscription } from "../../../AppDomain/UseCase/Stripe.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ButtonType, showModalEx } from "../ModalDialog.fs.js";
import { Daisy_confirm, Daisy_DialogWidth } from "../Component.fs.js";
import { IndexView } from "./Subscribe.fs.js";
import { map, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { fromDateTimeOffset } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";

export class State extends Record {
    constructor(Product, IsSubscribing, SubscriptionCount, HideModal, Errors) {
        super();
        this.Product = Product;
        this.IsSubscribing = IsSubscribing;
        this.SubscriptionCount = SubscriptionCount;
        this.HideModal = HideModal;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Component.Stripe.SubscriptionBar.State", [], State, () => [["Product", Product_$reflection()], ["IsSubscribing", bool_type], ["SubscriptionCount", SubscriptionCount_$reflection()], ["HideModal", bool_type], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OnCancelSubscription", "CancelSubscriptionResponse", "Nothing", "ToggleSubscribe", "SetSubscriptionCount", "ParseSubscriptionCount", "SubscriptionSuccessful", "ShowModalButton", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Component.Stripe.SubscriptionBar.Msg", [], Msg, () => [[["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", bool_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [SubscriptionCount_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", SubscriptionCount_$reflection()]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", unit_type]], [["Item", class_type("System.Exception")]]]);
}

export function init(product, props) {
    return [new State(product, false, zero, false, empty()), Cmd_none()];
}

export function cancelSubscriptionCmd(product, subscriptionId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => cancelSubscription(appRoot.StripeService, AppRootModule_getSelectedOrgId(), product, subscriptionId)));
}

export function showModalCmd() {
    return cmdOfAsync(() => (new Msg(7, [undefined])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => singleton.Return(undefined)));
}

export function update(msg, state) {
    switch (msg.tag) {
        case 3:
            return [new State(state.Product, msg.fields[0], state.SubscriptionCount, state.HideModal, state.Errors), Cmd_none()];
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Product, state.IsSubscribing, state.SubscriptionCount, state.HideModal, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        case 2:
            return [state, Cmd_none()];
        case 4:
            return [new State(state.Product, state.IsSubscribing, state.SubscriptionCount, state.HideModal, empty()), cmdOfFunc((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => create(parseInt(msg.fields[0], 0)))];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Product, state.IsSubscribing, state.SubscriptionCount, state.HideModal, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Product, state.IsSubscribing, msg.fields[0].fields[0], state.HideModal, state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.Product, false, state.SubscriptionCount, true, state.Errors), showModalCmd()];
        case 7:
            return [new State(state.Product, state.IsSubscribing, state.SubscriptionCount, false, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.Product, state.IsSubscribing, state.SubscriptionCount, state.HideModal, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [state, cancelSubscriptionCmd(state.Product, msg.fields[0])];
    }
}

export function subscriptionBar(subscriptionBarInputProps) {
    let elems, elems_3;
    const props = subscriptionBarInputProps.props;
    const isCheckingSubscription = subscriptionBarInputProps.isCheckingSubscription;
    const productSubscriptions = subscriptionBarInputProps.productSubscriptions;
    const isSignedIn = subscriptionBarInputProps.isSignedIn;
    const product = subscriptionBarInputProps.product;
    let patternInput;
    const init_1 = init(product, props.Product);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    if (isSignedIn) {
        if (isEmpty(productSubscriptions)) {
            if (isCheckingSubscription) {
                return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center"])], (elems = [createElement("button", createObj(Helpers_combineClasses("btn", singleton_1(["className", join(" ", ["relative inline-flex items-center justify-center btn-ghost loading btn-sm btn-circle"])])))), createElement("h5", {
                    className: join(" ", ["text-xl", "font-bold", "items-center"]),
                    children: "Checking subscription...",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
            }
            else if (state_1.HideModal) {
                return createElement("div", {});
            }
            else {
                return createElement(showModalEx, {
                    dialogId: "subscription-bar-subscribe-button",
                    header: "",
                    buttonType: new ButtonType(0, ["Subscribe"]),
                    width: new Daisy_DialogWidth(3, []),
                    body: () => (state_1.IsSubscribing ? createElement(IndexView, {
                        product: props.Product,
                        props: {
                            onSubscribed: () => {
                                props.OnSubscribed(true);
                                dispatch(new Msg(6, []));
                            },
                        },
                    }) : createElement("div", {})),
                    onShow: () => {
                        dispatch(new Msg(3, [true]));
                    },
                    onClose: () => {
                        dispatch(new Msg(3, [false]));
                    },
                });
            }
        }
        else {
            return createElement("div", createObj(singleton_1((elems_3 = toList(delay(() => append(state_1.HideModal ? singleton_2(createElement("div", {})) : singleton_2(createElement(showModalEx, {
                dialogId: "subscription-bar-add-more-subscriptions",
                header: "",
                buttonType: new ButtonType(0, ["Add more Subscriptions"]),
                width: new Daisy_DialogWidth(3, []),
                body: () => (state_1.IsSubscribing ? createElement(IndexView, {
                    product: props.Product,
                    props: {
                        onSubscribed: () => {
                            props.OnSubscribed(true);
                            dispatch(new Msg(6, []));
                        },
                    },
                }) : createElement("div", {})),
                onShow: () => {
                    dispatch(new Msg(3, [true]));
                },
                onClose: () => {
                    dispatch(new Msg(3, [false]));
                },
            })), delay(() => {
                let elems_2, children_3, children_1, children_7;
                return singleton_2(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", join(" ", ["flex", "w-full", "mt-4"])], (elems_2 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                    width: 20 + "%",
                    children: "Subscription Active",
                }), createElement("th", {
                    width: 20 + "%",
                    children: "Purchase Date",
                }), createElement("th", {
                    width: 20 + "%",
                    children: "Expiry Date",
                }), createElement("th", {
                    width: 20 + "%",
                    children: "Subscription Qty",
                }), createElement("th", {
                    width: 20 + "%",
                    children: "",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                })), (children_7 = toList(delay(() => map((productSubscription) => {
                    let elems_1, children_5;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_1 = [createElement("td", {
                        children: [toString(productSubscription.IsActive)],
                    }), createElement("td", {
                        children: [DateTime_dateTimeToStringWithDayName(fromDateTimeOffset(productSubscription.PurchaseDate, 0))],
                    }), createElement("td", {
                        children: [DateTime_dateTimeToStringWithDayName(fromDateTimeOffset(productSubscription.ExpiryDate, 0))],
                    }), createElement("td", {
                        children: [productSubscription.SubscriptionCount],
                    }), (children_5 = singleton_1(createElement(Daisy_confirm, {
                        buttonColor: ["className", "btn-primary"],
                        width: new Daisy_DialogWidth(1, []),
                        buttonTitle: "Cancel Subscription",
                        body: createElement("p", {
                            children: ["Would you like to Cancel your subscription?"],
                        }),
                        isEnabled: true,
                        yesAction: new Msg(0, [productSubscription.Id]),
                        noAction: new Msg(2, []),
                        dispatch: dispatch,
                    })), createElement("td", {
                        children: reactApi.Children.toArray(Array.from(children_5)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
                }, productSubscriptions))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_7)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))));
            })))), ["children", reactApi.Children.toArray(Array.from(elems_3))]))));
        }
    }
    else {
        return createElement("div", {});
    }
}

