import { toString, Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, decimal_type, enum_type, int32_type, getEnumNames, record_type, option_type, getUnionCases, name, bool_type, string_type, anonRecord_type, union_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { defaultArg } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { dayOfWeek, compare, minValue } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Date.js";
import { map, sort, sortBy } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { compare as compare_1, numberHash, comparePrimitives } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { ofArray, singleton, contains, filter, length } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { System_DateTime__DateTime_GetDatesBetween_Static } from "../../Util.fs.js";
import { MeasurementSystem_$reflection } from "./MeasurementUnits.fs.js";
import { tryFindKey, ofSeq } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Map.js";
import { isSubset, isEmpty, exists, ofSeq as ofSeq_1 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Set.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { isNullOrEmpty } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";
import { Email_$reflection } from "./Email.fs.js";
import { Status_$reflection } from "./Common.fs.js";

export class SubscriptionId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SubscriptionId"];
    }
}

export function SubscriptionId_$reflection() {
    return union_type("Domain.Entity.User.SubscriptionId", [], SubscriptionId, () => [[["Item", class_type("System.Guid")]]]);
}

export class UserId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["UserId"];
    }
}

export function UserId_$reflection() {
    return union_type("Domain.Entity.User.UserId", [], UserId, () => [[["Item", class_type("System.Guid")]]]);
}

export class LeaveRequestStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Draft", "Submitted", "Withdrawn", "Pending", "Approved", "Rejected"];
    }
}

export function LeaveRequestStatus_$reflection() {
    return union_type("Domain.Entity.User.LeaveRequestStatus", [], LeaveRequestStatus, () => [[], [], [], [], [["Item", anonRecord_type(["ApprovedBy", class_type("System.Guid")], ["DateApproved", class_type("System.DateTime")])]], [["Item", anonRecord_type(["DateRejected", class_type("System.DateTime")], ["RejectedBy", class_type("System.Guid")], ["RejectionReason", string_type])]]]);
}

export function LeaveRequestStatusModule_ofInt(dateApproved, approvedBy, dateRejected, rejectedBy, rejectionReason, _arg) {
    let DateApproved;
    let matchResult;
    switch (_arg) {
        case 0: {
            matchResult = 0;
            break;
        }
        case 1: {
            matchResult = 1;
            break;
        }
        case 2: {
            matchResult = 2;
            break;
        }
        case 3: {
            matchResult = 3;
            break;
        }
        case 4: {
            if ((dateApproved != null) && (approvedBy != null)) {
                matchResult = 4;
            }
            else {
                matchResult = 6;
            }
            break;
        }
        case 5: {
            if ((dateRejected != null) && (rejectedBy != null)) {
                matchResult = 5;
            }
            else {
                matchResult = 6;
            }
            break;
        }
        default:
            matchResult = 6;
    }
    switch (matchResult) {
        case 0:
            return new LeaveRequestStatus(0, []);
        case 1:
            return new LeaveRequestStatus(1, []);
        case 2:
            return new LeaveRequestStatus(2, []);
        case 3:
            return new LeaveRequestStatus(3, []);
        case 4:
            return new LeaveRequestStatus(4, [(DateApproved = defaultArg(dateApproved, minValue()), {
                ApprovedBy: defaultArg(approvedBy, "00000000-0000-0000-0000-000000000000"),
                DateApproved: DateApproved,
            })]);
        case 5:
            return new LeaveRequestStatus(5, [{
                DateRejected: defaultArg(dateRejected, minValue()),
                RejectedBy: defaultArg(rejectedBy, "00000000-0000-0000-0000-000000000000"),
                RejectionReason: defaultArg(rejectionReason, ""),
            }]);
        default:
            return new LeaveRequestStatus(0, []);
    }
}

export function LeaveRequestStatusModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        case 5:
            return 5;
        default:
            return 0;
    }
}

export function LeaveRequestStatusModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Submitted";
        case 2:
            return "Withdrawn";
        case 3:
            return "Pending";
        case 4:
            return "Approved";
        case 5:
            return "Rejected";
        default:
            return "Draft";
    }
}

export function LeaveRequestStatusModule_ofString(_arg) {
    switch (_arg) {
        case "Draft":
            return new LeaveRequestStatus(0, []);
        case "Submitted":
            return new LeaveRequestStatus(1, []);
        case "Withdrawn":
            return new LeaveRequestStatus(2, []);
        case "Pending":
            return new LeaveRequestStatus(3, []);
        case "Approved":
            return new LeaveRequestStatus(4, [{
                ApprovedBy: "00000000-0000-0000-0000-000000000000",
                DateApproved: minValue(),
            }]);
        case "Rejected":
            return new LeaveRequestStatus(5, [{
                DateRejected: minValue(),
                RejectedBy: "00000000-0000-0000-0000-000000000000",
                RejectionReason: "",
            }]);
        default:
            return new LeaveRequestStatus(0, []);
    }
}

export class LeaveRequestType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SickLeave", "AnnualLeave", "BereavementLeave", "UnpaidLeave", "Other"];
    }
}

export function LeaveRequestType_$reflection() {
    return union_type("Domain.Entity.User.LeaveRequestType", [], LeaveRequestType, () => [[["Item", anonRecord_type(["MedicalCertificateSubmitted", bool_type])]], [], [], [], []]);
}

export const LeaveRequestTypeModule_leaveRequestTypes = sortBy(name, getUnionCases(LeaveRequestType_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function LeaveRequestTypeModule_ofInt(medicalCertificateSubmitted, _arg) {
    switch (_arg) {
        case 0:
            return new LeaveRequestType(0, [{
                MedicalCertificateSubmitted: medicalCertificateSubmitted,
            }]);
        case 1:
            return new LeaveRequestType(1, []);
        case 2:
            return new LeaveRequestType(2, []);
        case 3:
            return new LeaveRequestType(3, []);
        case 4:
            return new LeaveRequestType(4, []);
        default:
            return new LeaveRequestType(4, []);
    }
}

export function LeaveRequestTypeModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        default:
            return 0;
    }
}

export function LeaveRequestTypeModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Annual Leave";
        case 2:
            return "Bereavement Leave";
        case 3:
            return "Unpaid Leave";
        case 4:
            return "Other";
        default:
            return "Sick Leave";
    }
}

export class LeaveRequest extends Record {
    constructor(Id, OrgId, UserId, StartDate, FinishDate, Status, LeaveRequestType, Reason, PayrollAdminComment, PayrollAdminCommentUser, Created, Modified) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.Status = Status;
        this.LeaveRequestType = LeaveRequestType;
        this.Reason = Reason;
        this.PayrollAdminComment = PayrollAdminComment;
        this.PayrollAdminCommentUser = PayrollAdminCommentUser;
        this.Created = Created;
        this.Modified = Modified;
    }
}

export function LeaveRequest_$reflection() {
    return record_type("Domain.Entity.User.LeaveRequest", [], LeaveRequest, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["StartDate", class_type("System.DateTime")], ["FinishDate", class_type("System.DateTime")], ["Status", LeaveRequestStatus_$reflection()], ["LeaveRequestType", LeaveRequestType_$reflection()], ["Reason", string_type], ["PayrollAdminComment", option_type(string_type)], ["PayrollAdminCommentUser", option_type(class_type("System.Guid"))], ["Created", class_type("System.DateTime")], ["Modified", class_type("System.DateTime")]]);
}

export function LeaveRequestModule_leaveDaysBetween(leaveStartDate, leaveFinishDate, start, finish, toTimeZone, daysOfWeekToIgnore) {
    if ((compare(leaveStartDate, finish) > 0) ? true : (compare(leaveFinishDate, start) < 0)) {
        return 0;
    }
    else {
        const startDate = (compare(leaveStartDate, start) < 0) ? start : leaveStartDate;
        const finishDate = (compare(leaveFinishDate, finish) > 0) ? finish : leaveFinishDate;
        return length(filter((x) => {
            if (!contains(dayOfWeek(toTimeZone(x)), daysOfWeekToIgnore, {
                Equals: (x_1, y) => (x_1 === y),
                GetHashCode: numberHash,
            })) {
                if (compare(x, startDate) >= 0) {
                    return compare(x, finishDate) <= 0;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }, System_DateTime__DateTime_GetDatesBetween_Static(start, finish))) | 0;
    }
}

export class LeaveRequestSearchResult extends Record {
    constructor(Id, OrgId, UserId, FirstName, MiddleName, LastName, StartDate, FinishDate, Status, ApprovedByUsername, RejectedByUsername, LeaveRequestType, Reason, PayrollAdminComment, PayrollAdminCommentUser, Created, Modified) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.Status = Status;
        this.ApprovedByUsername = ApprovedByUsername;
        this.RejectedByUsername = RejectedByUsername;
        this.LeaveRequestType = LeaveRequestType;
        this.Reason = Reason;
        this.PayrollAdminComment = PayrollAdminComment;
        this.PayrollAdminCommentUser = PayrollAdminCommentUser;
        this.Created = Created;
        this.Modified = Modified;
    }
}

export function LeaveRequestSearchResult_$reflection() {
    return record_type("Domain.Entity.User.LeaveRequestSearchResult", [], LeaveRequestSearchResult, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["StartDate", class_type("System.DateTime")], ["FinishDate", class_type("System.DateTime")], ["Status", LeaveRequestStatus_$reflection()], ["ApprovedByUsername", option_type(string_type)], ["RejectedByUsername", option_type(string_type)], ["LeaveRequestType", LeaveRequestType_$reflection()], ["Reason", string_type], ["PayrollAdminComment", string_type], ["PayrollAdminCommentUser", string_type], ["Created", class_type("System.DateTime")], ["Modified", class_type("System.DateTime")]]);
}

export class Training extends Record {
    constructor(Id, OrgId, Name) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.Name = Name;
    }
}

export function Training_$reflection() {
    return record_type("Domain.Entity.User.Training", [], Training, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Name", string_type]]);
}

export class UserTraining extends Record {
    constructor(Id, OrgId, UserId, TrainingId, CompletedAt) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.TrainingId = TrainingId;
        this.CompletedAt = CompletedAt;
    }
}

export function UserTraining_$reflection() {
    return record_type("Domain.Entity.User.UserTraining", [], UserTraining, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["TrainingId", class_type("System.Guid")], ["CompletedAt", class_type("System.DateTime")]]);
}

export class Skill extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function Skill_$reflection() {
    return record_type("Domain.Entity.User.Skill", [], Skill, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export class UserRole extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Admin", "OfficeAdmin", "PayrollAdmin", "Worker", "SiteManager", "Management", "Accounts"];
    }
}

export function UserRole_$reflection() {
    return union_type("Domain.Entity.User.UserRole", [], UserRole, () => [[], [], [], [], [], [], []]);
}

export const UserRoleModule_userRoles = sortBy(name, getUnionCases(MeasurementSystem_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function UserRoleModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new UserRole(0, []);
        case 1:
            return new UserRole(1, []);
        case 2:
            return new UserRole(3, []);
        case 3:
            return new UserRole(4, []);
        case 4:
            return new UserRole(2, []);
        case 5:
            return new UserRole(5, []);
        case 6:
            return new UserRole(6, []);
        default:
            throw new Error("Invalid UserRole int.");
    }
}

export function UserRoleModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 3:
            return 2;
        case 4:
            return 3;
        case 2:
            return 4;
        case 5:
            return 5;
        case 6:
            return 6;
        default:
            return 0;
    }
}

export function UserRoleModule_ofString(_arg) {
    switch (_arg) {
        case "Admin":
            return new UserRole(0, []);
        case "OfficeAdmin":
            return new UserRole(1, []);
        case "Worker":
            return new UserRole(3, []);
        case "SiteManager":
            return new UserRole(4, []);
        case "PayrollAdmin":
            return new UserRole(2, []);
        case "Management":
            return new UserRole(5, []);
        case "Accounts":
            return new UserRole(6, []);
        default:
            throw new Error(`Invalid UserRole string ${_arg}`);
    }
}

export class PermissionType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Read", "Add", "Edit", "Delete"];
    }
}

export function PermissionType_$reflection() {
    return union_type("Domain.Entity.User.PermissionType", [], PermissionType, () => [[], [], [], []]);
}

export class ModulePermission extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AnyModule", "User", "Leave", "Customer", "Vehicles", "Setting", "Job", "Shift", "Timesheet", "Payroll", "Invoice", "Report"];
    }
}

export function ModulePermission_$reflection() {
    return union_type("Domain.Entity.User.ModulePermission", [], ModulePermission, () => [[["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PermissionType_$reflection()])]]]);
}

const ModulePermissionModule_userRolePermissions = ofSeq([[new UserRole(0, []), ofSeq_1([new ModulePermission(0, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(1, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(2, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(3, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(4, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(5, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(6, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(7, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(8, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(9, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(10, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(11, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})])], {
    Compare: compare_1,
})], [new UserRole(1, []), ofSeq_1([new ModulePermission(0, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(1, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(2, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(3, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(4, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(5, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(6, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(7, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(8, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(9, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(10, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(11, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})])], {
    Compare: compare_1,
})], [new UserRole(2, []), ofSeq_1([new ModulePermission(0, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(1, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(2, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(3, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(4, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(5, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(6, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(7, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(8, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(9, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(10, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(11, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})])], {
    Compare: compare_1,
})], [new UserRole(4, []), ofSeq_1([new ModulePermission(0, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(1, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(2, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(4, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(5, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(6, [ofSeq_1([new PermissionType(0, []), new PermissionType(2, [])], {
    Compare: compare_1,
})]), new ModulePermission(7, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(8, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(9, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(10, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(11, [ofSeq_1([], {
    Compare: compare_1,
})])], {
    Compare: compare_1,
})], [new UserRole(3, []), ofSeq_1([new ModulePermission(0, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(1, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(2, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(4, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(5, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(6, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(7, [ofSeq_1([new PermissionType(1, []), new PermissionType(0, []), new PermissionType(2, [])], {
    Compare: compare_1,
})]), new ModulePermission(8, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(9, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(10, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(11, [ofSeq_1([], {
    Compare: compare_1,
})])], {
    Compare: compare_1,
})], [new UserRole(5, []), ofSeq_1([new ModulePermission(0, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(1, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(2, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(3, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(4, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(5, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(6, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(7, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(8, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(9, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(10, [ofSeq_1([], {
    Compare: compare_1,
})]), new ModulePermission(11, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})])], {
    Compare: compare_1,
})], [new UserRole(6, []), ofSeq_1([new ModulePermission(0, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(1, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(2, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(3, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(4, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(5, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(6, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(7, [ofSeq_1([new PermissionType(0, [])], {
    Compare: compare_1,
})]), new ModulePermission(8, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(9, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(10, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})]), new ModulePermission(11, [ofSeq_1([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, []), new PermissionType(3, [])], {
    Compare: compare_1,
})])], {
    Compare: compare_1,
})]], {
    Compare: compare_1,
});

function ModulePermissionModule_userRoleHasPermission(permission, requiredPermissions) {
    return exists((x) => {
        let matchResult, x_1, y;
        switch (x.tag) {
            case 1: {
                if (permission.tag === 1) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 2: {
                if (permission.tag === 2) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 3: {
                if (permission.tag === 3) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 4: {
                if (permission.tag === 4) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 6: {
                if (permission.tag === 6) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 7: {
                if (permission.tag === 7) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 8: {
                if (permission.tag === 8) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 9: {
                if (permission.tag === 9) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 5: {
                if (permission.tag === 5) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 10: {
                if (permission.tag === 10) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 11: {
                if (permission.tag === 11) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            default:
                if (permission.tag === 0) {
                    matchResult = 0;
                    x_1 = x.fields[0];
                    y = permission.fields[0];
                }
                else {
                    matchResult = 1;
                }
        }
        switch (matchResult) {
            case 0:
                if (!isEmpty(y)) {
                    return isSubset(y, x_1);
                }
                else {
                    return false;
                }
            default:
                return false;
        }
    }, requiredPermissions);
}

export function ModulePermissionModule_isAllowed(userRole, requiredPermission) {
    let input_1;
    const option_1 = tryFindKey((k, v) => {
        if (toString(k) === toString(userRole)) {
            return ModulePermissionModule_userRoleHasPermission(requiredPermission, v);
        }
        else {
            return false;
        }
    }, ModulePermissionModule_userRolePermissions);
    input_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton("Access Denied")])) : (new FSharpResult$2(0, [option_1])));
    if (input_1.tag === 1) {
        return new FSharpResult$2(1, [input_1.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [undefined]);
    }
}

export function SkillRatingModule_getLookupValues() {
    return ofArray(getEnumNames(enum_type("Domain.Entity.User.SkillRating", int32_type, [["Beginner", 0], ["Intermediate", 1], ["Experienced", 2]])));
}

export function SkillRatingModule_ofString(_arg) {
    switch (_arg) {
        case "Beginner":
            return 0;
        case "Intermediate":
            return 1;
        case "Experienced":
            return 2;
        default:
            return 0;
    }
}

export function SkillRatingModule_toString(_arg) {
    switch (_arg) {
        case 0:
            return "Beginner";
        case 1:
            return "Intermediate";
        case 2:
            return "Experienced";
        default:
            return "Beginner";
    }
}

export class UserSkill extends Record {
    constructor(Id, SkillId, ExperienceMonths, Country, Rating) {
        super();
        this.Id = Id;
        this.SkillId = SkillId;
        this.ExperienceMonths = (ExperienceMonths | 0);
        this.Country = Country;
        this.Rating = (Rating | 0);
    }
}

export function UserSkill_$reflection() {
    return record_type("Domain.Entity.User.UserSkill", [], UserSkill, () => [["Id", class_type("System.Guid")], ["SkillId", class_type("System.Guid")], ["ExperienceMonths", int32_type], ["Country", option_type(string_type)], ["Rating", enum_type("Domain.Entity.User.SkillRating", int32_type, [["Beginner", 0], ["Intermediate", 1], ["Experienced", 2]])]]);
}

export class PayrollSystemInfo extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "Smartly"];
    }
}

export function PayrollSystemInfo_$reflection() {
    return union_type("Domain.Entity.User.PayrollSystemInfo", [], PayrollSystemInfo, () => [[], [["Item", anonRecord_type(["PayRateCode", string_type], ["UserCode", string_type])]]]);
}

export function PayrollSystemInfoModule_payrollSystemIndex(_arg) {
    if (_arg.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export function PayrollSystemInfoModule_payrollIndexToString(_arg) {
    if (_arg === 1) {
        return "Smartly";
    }
    else {
        return "None";
    }
}

export const PayrollSystemInfoModule_payrollSystemNames = ofArray(sort(map(name, getUnionCases(PayrollSystemInfo_$reflection(), 0)), {
    Compare: comparePrimitives,
}));

export function PayrollSystemInfoModule_toString(_arg) {
    if (_arg.tag === 1) {
        return "Smartly";
    }
    else {
        return "None";
    }
}

export function PayrollSystemInfoModule_payrollSystemIndexOfString(payrollString) {
    if (payrollString === "Smartly") {
        return 1;
    }
    else {
        return 0;
    }
}

export function ResidencyStatusModule_getLookupValues() {
    return ofArray(getEnumNames(enum_type("Domain.Entity.User.ResidencyStatus", int32_type, [["NotSet", 0], ["Citizen", 1], ["PermanentResident", 2], ["WorkVisa", 3], ["StudentVisa", 4], ["ResidentVisa", 5]])));
}

export function ResidencyStatusModule_ofString(_arg) {
    switch (_arg) {
        case "Citizen":
            return 1;
        case "PermanentResident":
            return 2;
        case "WorkVisa":
            return 3;
        case "StudentVisa":
            return 4;
        case "ResidentVisa":
            return 5;
        default:
            return 0;
    }
}

export function ResidencyStatusModule_toString(_arg) {
    switch (_arg) {
        case 1:
            return "Citizen";
        case 2:
            return "PermanentResident";
        case 3:
            return "WorkVisa";
        case 4:
            return "StudentVisa";
        case 5:
            return "ResidentVisa";
        default:
            return "NotSet";
    }
}

export function AccommodationTypeModule_getLookupValues() {
    return ofArray(getEnumNames(enum_type("Domain.Entity.User.AccommodationType", int32_type, [["NotSet", 0], ["OwnHome", 1], ["Renting", 2], ["Boarding", 3], ["CompanyProvided", 4]])));
}

export function AccommodationTypeModule_ofString(_arg) {
    switch (_arg) {
        case "OwnHome":
            return 1;
        case "Renting":
            return 2;
        case "Boarding":
            return 3;
        case "CompanyProvided":
            return 4;
        default:
            return 0;
    }
}

export function AccommodationTypeModule_toString(_arg) {
    switch (_arg) {
        case 1:
            return "OwnHome";
        case 2:
            return "Renting";
        case 3:
            return "Boarding";
        case 4:
            return "CompanyProvided";
        default:
            return "NotSet";
    }
}

export function EnglishProficiencyModule_getLookupValues() {
    return ofArray(getEnumNames(enum_type("Domain.Entity.User.EnglishProficiency", int32_type, [["NotSet", 0], ["Fluent", 1], ["Good", 2], ["Basic", 3]])));
}

export function EnglishProficiencyModule_ofString(_arg) {
    switch (_arg) {
        case "Fluent":
            return 1;
        case "Good":
            return 2;
        case "Basic":
            return 3;
        default:
            return 0;
    }
}

export function EnglishProficiencyModule_toString(_arg) {
    switch (_arg) {
        case 1:
            return "Fluent";
        case 2:
            return "Good";
        case 3:
            return "Basic";
        default:
            return "NotSet";
    }
}

export const fullTimeWeekDays = 5;

export class EmploymentType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FullTime", "PartTime", "Casual", "Wages", "Contractor"];
    }
}

export function EmploymentType_$reflection() {
    return union_type("Domain.Entity.User.EmploymentType", [], EmploymentType, () => [[], [], [], [], []]);
}

export function EmploymentTypeModule_getLookupValues() {
    return sortBy(name, getUnionCases(EmploymentType_$reflection(), 0), {
        Compare: comparePrimitives,
    });
}

export function EmploymentTypeModule_ofString(_arg) {
    switch (_arg) {
        case "FullTime":
            return new EmploymentType(0, []);
        case "PartTime":
            return new EmploymentType(1, []);
        case "Casual":
            return new EmploymentType(2, []);
        case "Wages":
            return new EmploymentType(3, []);
        case "Contractor":
            return new EmploymentType(4, []);
        default:
            return new EmploymentType(0, []);
    }
}

export function EmploymentTypeModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "PartTime";
        case 2:
            return "Casual";
        case 3:
            return "Wages";
        case 4:
            return "Contractor";
        default:
            return "FullTime";
    }
}

export function EmploymentTypeModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        default:
            return 0;
    }
}

export function EmploymentTypeModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new EmploymentType(0, []);
        case 1:
            return new EmploymentType(1, []);
        case 2:
            return new EmploymentType(2, []);
        case 3:
            return new EmploymentType(3, []);
        case 4:
            return new EmploymentType(4, []);
        default:
            return new EmploymentType(0, []);
    }
}

export class User extends Record {
    constructor(Id, Username, IsActive, IsAdmin, FirstName, MiddleName, LastName, Mobile, MeasurementSystem, HourlyRate, ChargeOutRate, Rating, PayrollSystemInfo, UserSkills, UserRole, Dob, DriverLicenseCountry, DriverLicenseNumber, ResidencyStatus, EnglishProficiency, AccommodationType, OvertimeAfterWorkHours, EmploymentType, WeeklyFulltimeWorkHours) {
        super();
        this.Id = Id;
        this.Username = Username;
        this.IsActive = IsActive;
        this.IsAdmin = IsAdmin;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Mobile = Mobile;
        this.MeasurementSystem = MeasurementSystem;
        this.HourlyRate = HourlyRate;
        this.ChargeOutRate = ChargeOutRate;
        this.Rating = (Rating | 0);
        this.PayrollSystemInfo = PayrollSystemInfo;
        this.UserSkills = UserSkills;
        this.UserRole = UserRole;
        this.Dob = Dob;
        this.DriverLicenseCountry = DriverLicenseCountry;
        this.DriverLicenseNumber = DriverLicenseNumber;
        this.ResidencyStatus = (ResidencyStatus | 0);
        this.EnglishProficiency = (EnglishProficiency | 0);
        this.AccommodationType = (AccommodationType | 0);
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
    }
    toString() {
        const this$ = this;
        return (this$.FirstName + (isNullOrEmpty(this$.MiddleName) ? "" : (` ${this$.MiddleName}`))) + (` ${this$.LastName}`);
    }
}

export function User_$reflection() {
    return record_type("Domain.Entity.User.User", [], User, () => [["Id", class_type("System.Guid")], ["Username", Email_$reflection()], ["IsActive", bool_type], ["IsAdmin", bool_type], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Mobile", string_type], ["MeasurementSystem", MeasurementSystem_$reflection()], ["HourlyRate", decimal_type], ["ChargeOutRate", decimal_type], ["Rating", int32_type], ["PayrollSystemInfo", option_type(PayrollSystemInfo_$reflection())], ["UserSkills", list_type(UserSkill_$reflection())], ["UserRole", UserRole_$reflection()], ["Dob", option_type(class_type("System.DateTime"))], ["DriverLicenseCountry", option_type(string_type)], ["DriverLicenseNumber", option_type(string_type)], ["ResidencyStatus", enum_type("Domain.Entity.User.ResidencyStatus", int32_type, [["NotSet", 0], ["Citizen", 1], ["PermanentResident", 2], ["WorkVisa", 3], ["StudentVisa", 4], ["ResidentVisa", 5]])], ["EnglishProficiency", enum_type("Domain.Entity.User.EnglishProficiency", int32_type, [["NotSet", 0], ["Fluent", 1], ["Good", 2], ["Basic", 3]])], ["AccommodationType", enum_type("Domain.Entity.User.AccommodationType", int32_type, [["NotSet", 0], ["OwnHome", 1], ["Renting", 2], ["Boarding", 3], ["CompanyProvided", 4]])], ["OvertimeAfterWorkHours", decimal_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))]]);
}

export class UserMembership extends Record {
    constructor(OrganisationName, IsActive) {
        super();
        this.OrganisationName = OrganisationName;
        this.IsActive = IsActive;
    }
}

export function UserMembership_$reflection() {
    return record_type("Domain.Entity.User.UserMembership", [], UserMembership, () => [["OrganisationName", string_type], ["IsActive", bool_type]]);
}

export class AllocatedJobSkillUser extends Record {
    constructor(Id, JobUserSkillId, User) {
        super();
        this.Id = Id;
        this.JobUserSkillId = JobUserSkillId;
        this.User = User;
    }
}

export function AllocatedJobSkillUser_$reflection() {
    return record_type("Domain.Entity.User.AllocatedJobSkillUser", [], AllocatedJobSkillUser, () => [["Id", class_type("System.Guid")], ["JobUserSkillId", class_type("System.Guid")], ["User", User_$reflection()]]);
}

export class UserReport extends Record {
    constructor(Id, FirstName, MiddleName, LastName, Rating, HourlyRate, ChargeOutRate, UserSkills, DriverLicenseCountry, DriverLicenseNumber, ResidencyStatus, EnglishProficiency, AccommodationType, Age) {
        super();
        this.Id = Id;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Rating = (Rating | 0);
        this.HourlyRate = HourlyRate;
        this.ChargeOutRate = ChargeOutRate;
        this.UserSkills = UserSkills;
        this.DriverLicenseCountry = DriverLicenseCountry;
        this.DriverLicenseNumber = DriverLicenseNumber;
        this.ResidencyStatus = (ResidencyStatus | 0);
        this.EnglishProficiency = (EnglishProficiency | 0);
        this.AccommodationType = (AccommodationType | 0);
        this.Age = Age;
    }
}

export function UserReport_$reflection() {
    return record_type("Domain.Entity.User.UserReport", [], UserReport, () => [["Id", class_type("System.Guid")], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Rating", int32_type], ["HourlyRate", decimal_type], ["ChargeOutRate", decimal_type], ["UserSkills", list_type(string_type)], ["DriverLicenseCountry", string_type], ["DriverLicenseNumber", string_type], ["ResidencyStatus", enum_type("Domain.Entity.User.ResidencyStatus", int32_type, [["NotSet", 0], ["Citizen", 1], ["PermanentResident", 2], ["WorkVisa", 3], ["StudentVisa", 4], ["ResidentVisa", 5]])], ["EnglishProficiency", enum_type("Domain.Entity.User.EnglishProficiency", int32_type, [["NotSet", 0], ["Fluent", 1], ["Good", 2], ["Basic", 3]])], ["AccommodationType", enum_type("Domain.Entity.User.AccommodationType", int32_type, [["NotSet", 0], ["OwnHome", 1], ["Renting", 2], ["Boarding", 3], ["CompanyProvided", 4]])], ["Age", option_type(int32_type)]]);
}

export class SiteManager extends Record {
    constructor(Id, Username) {
        super();
        this.Id = Id;
        this.Username = Username;
    }
}

export function SiteManager_$reflection() {
    return record_type("Domain.Entity.User.SiteManager", [], SiteManager, () => [["Id", class_type("System.Guid")], ["Username", string_type]]);
}

export class UserDeviceMessagingToken extends Record {
    constructor(Id, UserId, DeviceId, MessagingToken, TokenExpiry) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.DeviceId = DeviceId;
        this.MessagingToken = MessagingToken;
        this.TokenExpiry = TokenExpiry;
    }
}

export function UserDeviceMessagingToken_$reflection() {
    return record_type("Domain.Entity.User.UserDeviceMessagingToken", [], UserDeviceMessagingToken, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["DeviceId", class_type("System.Guid")], ["MessagingToken", string_type], ["TokenExpiry", class_type("System.DateTime")]]);
}

export class UserLeavesResponse extends Record {
    constructor(Leaves, HasMoreRecords) {
        super();
        this.Leaves = Leaves;
        this.HasMoreRecords = HasMoreRecords;
    }
}

export function UserLeavesResponse_$reflection() {
    return record_type("Domain.Entity.User.UserLeavesResponse", [], UserLeavesResponse, () => [["Leaves", list_type(LeaveRequest_$reflection())], ["HasMoreRecords", bool_type]]);
}

export class UserDetail extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function UserDetail_$reflection() {
    return record_type("Domain.Entity.User.UserDetail", [], UserDetail, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export const leaveRequestStatuses = sortBy(name, getUnionCases(LeaveRequestStatus_$reflection(), 0), {
    Compare: comparePrimitives,
});

export class LeaveSearchDetails extends Record {
    constructor(SearchText, StartDate, FinishDate, Status, LeaveRequestType) {
        super();
        this.SearchText = SearchText;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.Status = Status;
        this.LeaveRequestType = LeaveRequestType;
    }
}

export function LeaveSearchDetails_$reflection() {
    return record_type("Domain.Entity.User.LeaveSearchDetails", [], LeaveSearchDetails, () => [["SearchText", string_type], ["StartDate", option_type(class_type("System.DateTimeOffset"))], ["FinishDate", option_type(class_type("System.DateTimeOffset"))], ["Status", option_type(LeaveRequestStatus_$reflection())], ["LeaveRequestType", option_type(LeaveRequestType_$reflection())]]);
}

export class UserSearchDetails extends Record {
    constructor(SearchText, Status) {
        super();
        this.SearchText = SearchText;
        this.Status = Status;
    }
}

export function UserSearchDetails_$reflection() {
    return record_type("Domain.Entity.User.UserSearchDetails", [], UserSearchDetails, () => [["SearchText", string_type], ["Status", Status_$reflection()]]);
}

