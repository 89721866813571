import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { SubscriptionDto } from "../../../Logos.Shared/Domain/Entity/Stripe.fs.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { SubscriptionUserAllocationDtoModule_ofSubscriptionUserAllocation } from "../../../Logos.Shared/Domain/Dto/Product.fs.js";

export function getStripeKey(stripeService) {
    return singleton.Delay(() => stripeService.GetStripeKey());
}

export function createInactiveSubscription(stripeService, selectedOrgId, productId, priceId, subscriptionCount) {
    return singleton.Delay(() => {
        const subscription_1 = new SubscriptionDto(productId, priceId, subscriptionCount);
        return stripeService.CreateInactiveSubscription(selectedOrgId, subscription_1);
    });
}

export function isProductSubscribed(stripeService, selectedOrgId, product) {
    return singleton.Delay(() => stripeService.GetProductSubscriptionDetails(selectedOrgId, product));
}

export function cancelSubscription(stripeService, selectedOrgId, product, subscriptionId) {
    return singleton.Delay(() => stripeService.CancelSubscription(selectedOrgId, product, subscriptionId));
}

export function getSubscriptionUserAllocations(stripeService, selectedOrgId, product, searchText, pagination) {
    return singleton.Delay(() => stripeService.GetSubscriptionUserAllocations(selectedOrgId, product, searchText, pagination));
}

export function allocateUserToSubscription(stripeService, selectedOrgId, subscriptionsToAllocate) {
    return singleton.Delay(() => {
        const subscriptionsToAllocate_2 = map(SubscriptionUserAllocationDtoModule_ofSubscriptionUserAllocation, subscriptionsToAllocate);
        return stripeService.AllocateUserToSubscription(selectedOrgId, subscriptionsToAllocate_2);
    });
}

export function removeUserSubscription(stripeService, selectedOrgId, subscriptionAllocationId, subscriptionId, userId) {
    return singleton.Delay(() => stripeService.RemoveUserSubscription(selectedOrgId, subscriptionAllocationId, subscriptionId, userId));
}

