import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, record_type, string_type, list_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Product as Product_1, ProductSubscription_$reflection } from "../../../../Logos.Shared/Domain/Entity/Product.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Result_traverse, AsyncResult_bindOfResult } from "../../../../Logos.Shared/Util.fs.js";
import { ofArray, singleton as singleton_1, empty, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { ProductSubscriptionDtoModule_toProductSubscription } from "../../../../Logos.Shared/Domain/Dto/Product.fs.js";
import { isProductSubscribed } from "../../../AppDomain/UseCase/Stripe.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Page, Cmd_navigateToPage } from "../../Router.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_error, Daisy_h2 } from "../../Component/Component.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { subscriptionBar } from "../../Component/Stripe/SubscriptionBar.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class State extends Record {
    constructor(IsSignedIn, ProductSubscriptions, IsSubscribing, IsCheckingSubscription, Errors) {
        super();
        this.IsSignedIn = IsSignedIn;
        this.ProductSubscriptions = ProductSubscriptions;
        this.IsSubscribing = IsSubscribing;
        this.IsCheckingSubscription = IsCheckingSubscription;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.Product.RememberWhereInfo.State", [], State, () => [["IsSignedIn", bool_type], ["ProductSubscriptions", list_type(ProductSubscription_$reflection())], ["IsSubscribing", bool_type], ["IsCheckingSubscription", bool_type], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GotoSignup", "SubscriptionStatus", "GetProductSubscriptionResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Product.RememberWhereInfo.Msg", [], Msg, () => [[], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ProductSubscription_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(ProductSubscription_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function isProductSubscribedCmd() {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => AsyncResult_bindOfResult((arg) => Result_traverse(map(ProductSubscriptionDtoModule_toProductSubscription, arg)), isProductSubscribed(appRoot.StripeService, AppRootModule_getSelectedOrgId(), new Product_1(0, [])))));
}

export function init(systemService) {
    let x;
    let isSignedIn;
    const input_1 = systemService.Load("UserData");
    isSignedIn = ((input_1.tag === 1) ? (new FSharpResult$2(1, [input_1.fields[0]])) : (new FSharpResult$2(0, [(x = input_1.fields[0], (x == null) ? false : (x.Token != null))])));
    const emptyState = new State(false, empty(), false, true, empty());
    if (isSignedIn.tag === 1) {
        return [new State(emptyState.IsSignedIn, emptyState.ProductSubscriptions, emptyState.IsSubscribing, emptyState.IsCheckingSubscription, isSignedIn.fields[0]), Cmd_none()];
    }
    else {
        const x_2 = isSignedIn.fields[0];
        return [new State(x_2, emptyState.ProductSubscriptions, emptyState.IsSubscribing, emptyState.IsCheckingSubscription, empty()), x_2 ? isProductSubscribedCmd() : Cmd_none()];
    }
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.IsSignedIn, state.ProductSubscriptions, !msg.fields[0], state.IsCheckingSubscription, state.Errors), msg.fields[0] ? isProductSubscribedCmd() : Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsSignedIn, empty(), state.IsSubscribing, false, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.IsSignedIn, msg.fields[0].fields[0], state.IsSubscribing, false, state.Errors), Cmd_none()];
            }
        case 3:
            return [new State(state.IsSignedIn, state.ProductSubscriptions, state.IsSubscribing, state.IsCheckingSubscription, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [state, Cmd_navigateToPage(new Page(19, []))];
    }
}

export function IndexView() {
    let elems_2, elems_1, value_13, value_20, value_22, value_24, value_38, value_45, elems;
    let patternInput;
    const init_1 = init(appRoot.SystemService);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["items-center", "justify-center", "py-8", "px-4", "sm:px-6", "lg:px-40"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["space-y-4", "box-content", "bg-white", "border", "border-2", "py-12", "px-4", "sm:px-6", "lg:px-8"])], (elems_1 = [Daisy_h2("RememberWhere"), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), Daisy_error(state_1.Errors), createElement(subscriptionBar, {
        product: new Product_1(0, []),
        isSignedIn: state_1.IsSignedIn,
        productSubscriptions: state_1.ProductSubscriptions,
        isCheckingSubscription: state_1.IsCheckingSubscription,
        props: {
            OnSubscribed: (isFinished) => {
                patternInput[1](new Msg(1, [isFinished]));
            },
            Product: new Product_1(0, []),
        },
    }), createElement("br", {}), createElement("p", {
        children: "RememberWhere helps you remind where things were kept.",
    }), createElement("p", {
        children: "To backup RememberWhere data safely you can signup and pay half yearly fee of US$5.00.",
    }), createElement("br", {}), createElement("p", {
        children: "Open the app to see the main screen.",
    }), createElement("img", {
        src: "./image/StressMaster/main-screen.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Main screen",
    }), createElement("p", createObj(singleton_1((value_13 = "Before using the app you can change the settings \n                            by clicking the settings icon at bottom right.\n                            This will show the settings screen.", ["children", value_13])))), createElement("img", {
        src: "./image/StressMaster/setting-screen.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Setting screen",
    }), createElement("p", createObj(singleton_1((value_20 = "Adjust the settings to not strain while inhaling and exhaling and press\n                        the `Save` button to save the changes.", ["children", value_20])))), createElement("p", createObj(singleton_1((value_22 = "There are four reminders that can be set to help you remind when\n                        to do breathing exercise. The reminders can be disabled if required.", ["children", value_22])))), createElement("br", {}), createElement("p", createObj(singleton_1((value_24 = "To start a session click on the Start button and follow the \n                            instructions on screen to inhale and exhale.", ["children", value_24])))), createElement("img", {
        src: "./image/StressMaster/start-session.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Start session",
    }), createElement("p", {
        children: "The countdown starts from the max repetition count number.",
    }), createElement("img", {
        src: "./image/StressMaster/inhale.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Inhale",
    }), createElement("p", createObj(singleton_1((value_38 = "A second before the session switches to exhale the color changes\n                            and is an indication to stop inhaling and prepare to exhale.", ["children", value_38])))), createElement("img", {
        src: "./image/StressMaster/exhale.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Exhale",
    }), createElement("p", createObj(singleton_1((value_45 = "This process continues till all the repetitions have finished.\n                            The session can be stopped if needed by clicking on the Cancel button in\n                            the bottom right hand corner.", ["children", value_45])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("a", createObj(ofArray([["href", "https://play.google.com/store/apps/details?id=com.tenthdoor.stressmaster"], ["target", "_blank"], (elems = [createElement("img", {
        className: join(" ", ["btn", "p-0"]),
        src: "./image/google-play-badge.png",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

