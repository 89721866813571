import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { string_type, union_type, record_type, lambda_type, unit_type, bool_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_DialogWidth__toStringEx, Daisy_h2, Daisy_DialogWidth__toString } from "./Component.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class State extends Record {
    constructor(IsShowing, onShow, onClose) {
        super();
        this.IsShowing = IsShowing;
        this.onShow = onShow;
        this.onClose = onClose;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Component.ModalDialog.State", [], State, () => [["IsShowing", bool_type], ["onShow", lambda_type(unit_type, unit_type)], ["onClose", lambda_type(unit_type, unit_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Show", "Close"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Component.ModalDialog.Msg", [], Msg, () => [[], []]);
}

export function init(onShow, onClose) {
    return [new State(false, onShow, onClose), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        state.onClose();
        return [new State(false, state.onShow, state.onClose), Cmd_none()];
    }
    else {
        if (!state.IsShowing) {
            state.onShow();
        }
        return [new State(!state.IsShowing, state.onShow, state.onClose), Cmd_none()];
    }
}

export function showModalDialog(showModalDialogInputProps) {
    let elems_6;
    const onClose = showModalDialogInputProps.onClose;
    const onShow = showModalDialogInputProps.onShow;
    const body = showModalDialogInputProps.body;
    const heightPercent = showModalDialogInputProps.heightPercent;
    const width = showModalDialogInputProps.width;
    const header = showModalDialogInputProps.header;
    const dialogId = showModalDialogInputProps.dialogId;
    let modalId;
    let copyOfStruct = newGuid();
    modalId = copyOfStruct;
    let patternInput;
    const init_1 = init(onShow, onClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [dialogId]);
    const children_3 = ofArray([createElement("input", createObj(Helpers_combineClasses("modal-toggle", ofArray([["type", "checkbox"], ["id", modalId], ["checked", true], ["readOnly", true]])))), createElement("div", createObj(Helpers_combineClasses("modal", singleton_1((elems_6 = [createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", [`modal-box ${Daisy_DialogWidth__toString(width)}`, "p-0"])]), delay(() => append((heightPercent != null) ? singleton(["style", {
        height: `${heightPercent}vh`,
    }]) : empty(), delay(() => {
        let elems_5, elems_3, elems_2, elems_1, elems, elems_4;
        return singleton((elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["sticky", "top-0", "bg-white", "p-4", "opacity-100", "z-50"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems_2 = [Daisy_h2(header), createElement("div", createObj(Helpers_combineClasses("modal-action", ofArray([["className", "modal-action-edit"], (elems_1 = [createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", modalId], ["className", "btn-xs"], ["className", "flex items-center justify-center w-8 h-8 rounded-full bg-red-500 text-white"], ["onClick", (_arg_2) => {
            patternInput[1](new Msg(1, []));
        }], (elems = [createElement("i", {
            className: "fas fa-times",
            style: {
                color: "white",
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["overflow-y-auto", "p-4"])], (elems_4 = [body()], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))]));
    }))))))))], ["children", reactApi.Children.toArray(Array.from(elems_6))])))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    });
}

export function showModal(showModalInputProps) {
    const onClose = showModalInputProps.onClose;
    const onShow = showModalInputProps.onShow;
    const body = showModalInputProps.body;
    const heightPercent = showModalInputProps.heightPercent;
    const width = showModalInputProps.width;
    const buttonText = showModalInputProps.buttonText;
    const header = showModalInputProps.header;
    const dialogId = showModalInputProps.dialogId;
    let modalId;
    let copyOfStruct = newGuid();
    modalId = copyOfStruct;
    let patternInput;
    const init_1 = init(onShow, onClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [dialogId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const children_4 = toList(delay(() => append(singleton(createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", modalId], ["className", "btn-primary"], ["className", "btn-sm"], ["children", buttonText], ["disabled", false]]))))), delay(() => append(singleton(createElement("input", createObj(Helpers_combineClasses("modal-toggle", ofArray([["type", "checkbox"], ["id", modalId], ["onClick", (_arg_2) => {
        dispatch(new Msg(0, []));
    }]]))))), delay(() => {
        let elems_5;
        return state_1.IsShowing ? singleton(createElement("div", createObj(Helpers_combineClasses("modal", singleton_1((elems_5 = [createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", [`modal-box ${Daisy_DialogWidth__toString(width)}`, "p-0"])]), delay(() => append((heightPercent != null) ? singleton(["style", {
            height: `${heightPercent}vh`,
        }]) : empty(), delay(() => {
            let elems_4, elems_2, elems_1, elems, elems_3;
            return singleton((elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["sticky", "top-0", "bg-white", "p-4"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems_1 = [Daisy_h2(header), createElement("div", createObj(Helpers_combineClasses("modal-action", ofArray([["className", "modal-action-edit"], (elems = [createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", modalId], ["className", "btn-error"], ["className", "btn-sm"], ["children", "Close"], ["onClick", (_arg_3) => {
                dispatch(new Msg(1, []));
            }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["overflow-y-auto", "p-4"])], (elems_3 = toList(delay(() => (state_1.IsShowing ? singleton(body()) : singleton(createElement("div", {}))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))]));
        }))))))))], ["children", reactApi.Children.toArray(Array.from(elems_5))])))))) : empty();
    }))))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

export class ButtonType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Simple", "Icon"];
    }
}

export function ButtonType_$reflection() {
    return union_type("Presentation.Component.ModalDialog.ButtonType", [], ButtonType, () => [[["ButtonText", string_type]], [["Icon", string_type]]]);
}

export function showModalEx(showModalExInputProps) {
    let elems_6;
    const onClose = showModalExInputProps.onClose;
    const onShow = showModalExInputProps.onShow;
    const body = showModalExInputProps.body;
    const heightPercent = showModalExInputProps.heightPercent;
    const width = showModalExInputProps.width;
    const buttonType = showModalExInputProps.buttonType;
    const header = showModalExInputProps.header;
    const dialogId = showModalExInputProps.dialogId;
    let modalId;
    let copyOfStruct = newGuid();
    modalId = copyOfStruct;
    let patternInput;
    const init_1 = init(onShow, onClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [dialogId]);
    const dispatch = patternInput[1];
    return createElement("div", createObj(ofArray([["id", modalId], (elems_6 = toList(delay(() => {
        let matchValue, elems;
        return append((matchValue = buttonType, (matchValue.tag === 1) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems = [createElement("i", {
            className: `fas ${matchValue.fields[0]}`,
        })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg_3) => {
            dispatch(new Msg(0, []));
        }]])))) : singleton(createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", matchValue.fields[0]], ["disabled", false], ["onClick", (_arg_2) => {
            dispatch(new Msg(0, []));
        }]])))))), delay(() => {
            let elems_5;
            return patternInput[0].IsShowing ? singleton(createElement("div", createObj(ofArray([["className", "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"], (elems_5 = [createElement("div", createObj(toList(delay(() => append(singleton(["className", `bg-white ${Daisy_DialogWidth__toStringEx(width)} overflow-auto rounded-lg`]), delay(() => append((heightPercent != null) ? singleton(["style", {
                height: `${heightPercent}vh`,
            }]) : singleton(["style", {
                height: "80vh",
            }]), delay(() => {
                let elems_4, elems_3, elems_1, elems_2;
                return singleton((elems_4 = [createElement("div", createObj(ofArray([["className", "flex justify-between sticky top-0 px-3 py-3 bg-white opacity-100"], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex justify-start"])], (elems_1 = [Daisy_h2(header)], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-xs"], ["className", "flex items-center justify-center w-8 h-8 rounded-full bg-red-500 text-white"], ["onClick", (_arg_4) => {
                    dispatch(new Msg(1, []));
                }], (elems_2 = [createElement("i", {
                    className: "fas fa-times",
                    style: {
                        color: "white",
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), body()], ["children", reactApi.Children.toArray(Array.from(elems_4))]));
            }))))))))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty();
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])));
}

